@media screen and (min-width: 1200px) and (max-width:2000px) {
    .table-refferal-main .refferal-main-paper-table {
        max-width: calc(100vw - 486px);
    }
}


@media screen and (min-width: 900px) and (max-width:1199px) {
    .fitness-goal-flex .fitness-goal-box {
        padding: 12px 9px;
    }

    .custom-group .form-label,
    .height-radio-main .form-heightgroup-control .MuiFormControlLabel-label {
        font-size: 12px;
    }

    .input-link-main .history-link-control input {
        min-width: 409px;
        font-size: 13px;
    }

    .table-refferal-main .refferal-main-paper-table {
        max-width: calc(100vw - 376px);
    }

    .table-refferal-main .refferal-main-table {
        min-width: 600px;
    }

    .user-header-logo {
        max-width: initial;
    }

    img.fitness-img {
        height: 35px;
        width: 35px;
    }

    .fitness-goal-box .fitness-title-main {
        font-size: 12px;
        line-height: 19px;
    }

    .profile-step-details .steper-box-profile {
        height: 40px;
        width: 40px;
    }

    .profile-stepper-amin::after {
        top: 19px;
    }

    .profile-step-details .profile-steplabel-main {
        font-size: 13px;
    }

    .sidebar-user-main .list-user-sidebar .listitem-user-sidebar .user-sidebar-link {
        font-size: 16px;
        line-height: 18px;
    }

    .referral-card-list-details .refrrel-icon {
        width: 70px;
        height: 70px;
    }

    .title-reffrel-program .title-pro-refreel {
        font-size: 14px;
        line-height: 23px;
    }

    .referral-details-list .referral-details-card {
        padding: 5px 0px;
    }

    .referral-content-details .referral-details-card {
        font-size: 11px;
        line-height: 18px;
    }

    .min-height-255px {
        min-height: 246.5px;
    }

    .min-height-625px {
        min-height: 503px;
    }

    .title-reffrel-program .prices-pro-refreel {
        font-size: 12px;
        line-height: 17px;
    }

    .svg-rounded-border {
        left: -31px;
        top: -55px;
    }

    .login-pd-main,
    .login-user-main .login-pd-main {
        padding: 30px 30px;
    }

    .login-form-details .login-max-content,
    .signup-form-details .signup-max-details {
        max-width: initial;
        margin: initial;
    }

    .container {
        max-width: 960px;
    }

    .title-details-joyful .joyful-spirit-title {
        font-size: 55px;
        line-height: 61px;
        margin: auto auto 57px auto;
    }

    .virtual-box-list .customize-joyful-details {
        font-size: 19px;
        line-height: 30px;
        max-width: inherit;
    }


    .joyful-bg-top {
        top: 0px;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .joyful-race-content {
        height: 479px;
    }

    .jyoful-main-content .pd-joyful-investment {
        padding: 70px 0px 70px 0px;
    }

    .roadmap-pd-list {
        padding: 70px 0px 304px 0px;
    }

    .roadmap-title-content .title-roadmap-details,
    .referral-main-title-box .small-referral-title,
    .contact-us-details .contactus-title {
        font-size: 50px;
        line-height: 60px;
    }

    .roadmap-title-content .title-roadmap-details {
        margin-bottom: 91.7px;
    }

    .roadmap-title-content .shadow-contnet-title-comman,
    .referral-main-title-box .shadow-referral-title,
    .contact-us-details .contactus-title-shadow {
        font-size: 67px;
        line-height: 76px;
    }

    .referral-friend-content {
        padding-top: 40px;
    }

    .referral-friend-content::after {
        width: 381px;
        height: 633px;
        background-repeat: no-repeat;
        background-size: cover;
        top: -2px;
    }

    .refrral-friend-bg .refrral-mobile-img {
        height: 548px;
    }

    .footer-bg-rounded {
        height: initial;
    }

    .svg-rounded-border .footer-line-bg {
        max-width: 487px;
        height: 487px;
    }

    .footer-logo {
        height: 100px;
        max-width: 150px;
        margin: auto auto 20px auto;
    }

    .address-footer-main {
        margin-bottom: 23px;
    }

    .footer-details-main .footer-title {
        line-height: 49px;
        font-size: 25px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1199px) and (orientation : landscape) {
    .svg-rounded-border .footer-line-bg {
        display: none;
    }



}

@media screen and (max-width:991px) {
    .bms-btn-content {
        margin-top: 10px;
    }

    /* user dashboard menu start */
    .sidebar-user-main .list-user-sidebar .listitem-user-sidebar.active .user-sidebar-link {
        color: #ffffff;
    }

    .sidebar-user-main .list-user-sidebar .listitem-user-sidebar .user-sidebar-link {
        color: #ffff;
        margin-bottom: 0px;
    }

    .sidebar-user-main .list-user-sidebar .listitem-user-sidebar {
        padding: 10px 15px;
    }

    .sidebar-user-main {
        padding: 10px 0px;
        position: fixed;
        left: -253px;
        transition: 0.5s all;
        top: 0;
        background: #032058;
        min-width: 250px;
        z-index: 999;
        height: 100vh;
        bottom: 0;
        overflow: auto;
    }

    .userdash-col1 {
        display: none;
    }



    /* user dashboard menu end */


    .mobile-show.overlay-mobile-active {
        display: block !important;
    }

    .center-list-header .header-ul-list.header-left-active {
        left: 0px;
    }

    .header-override {
        overflow: hidden;
    }

    .overlay-section {
        background: hsla(0, 3%, 8%, .5);
        bottom: 0;
        max-width: 100% !important;
        position: fixed !important;
        right: 0;
        top: 0 !important;
        transition: all .3s !important;
        width: 100% !important;
        z-index: 99;
    }

    .burgur-icon-main {
        display: block;
    }

    .center-list-header .header-ul-list {
        padding: 10px 0px;
        position: fixed;
        left: -253px;
        transition: 0.5s all;
        flex-direction: column;
        top: 0;
        background: #032058;
        min-width: 250px;
        z-index: 999;
        height: 100vh;
        bottom: 0;
        overflow: auto;
    }

    .center-list-header .header-ul-list .header-listitem-li {
        padding: 10px 20px;
        width: 100%;
        text-align: start;
        max-width: 100%;
    }
}


@media screen and (min-width: 600px) and (max-width: 899px) {
    .header-logo {
        object-fit: contain;
        height: 40px;
        min-width: initial;
        width: inherit;
    }

    .table-refferal-main .refferal-main-paper-table {
        max-width: calc(100vw - 166px);
    }

    .table-refferal-main .refferal-main-table {
        min-width: 700px;
    }

    .input-link-main .history-link-control input {
        min-width: 429px;
    }

    .fitness-goal-flex {
        width: calc(100% / 3);
    }

    .custom-group .form-label {
        font-size: 10px;
        line-height: 15px;
        margin-bottom: 6px;
    }

    .flex-center-radio-hgt .form-label {
        margin-bottom: 0px;
    }

    .height-radio-main .form-heightgroup-control .MuiFormControlLabel-label {
        font-size: 11px;
        line-height: 15px;
    }

    .profile-step-details .profile-steplabel-main {
        font-size: 13px;
        line-height: 19px;
    }

    .referral-content-details {
        width: 100%;
    }

    .referral-card-list-details .refrrel-icon {
        width: 55px;
        height: 55px;
    }

    .title-reffrel-program .title-pro-refreel {
        font-size: 16px;
        line-height: 20px;
    }

    .title-reffrel-program .prices-pro-refreel {
        font-size: 12px;
        line-height: 21px;
    }

    .login-bg-content,
    .signup-bg-content {
        padding: 15px 0px;
        height: 30vh;
        background-image: none;
        background-color: #032058;
    }

    .signup-form-details .signup-max-details {
        max-width: initial;
        margin: initial;
        width: 100%;
    }

    .signup-form-details .signin-btn-main .comman-primary-btn {
        min-width: 49%;
    }
    /* admin & user button loading start */
    .comman-loader-button {
        min-width: 49%;
    }
   
    
    /* admin & user button loading end */
    .login-form-details .login-max-content {
        min-width: 400px;
    }

    .mr-hgt-scroll {
        margin-right: 0px;
    }

    .login-user-main .login-pd-main {
        padding: 30px 30px;
        height: calc(100vh - 30vh);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .login-pd-main {
        padding: 30px 30px;
        height: calc(100vh - 30vh);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        /* height: max-content; */
    }

    .signup-scroll-form {
        max-height: initial;
        overflow-y: initial;
        padding-right: 0px;
    }

    .container {
        max-width: 720px;
    }

    .login-logo-details .logo-login {
        height: 120px;
    }

    .logo-main-left,
    .logo-header {
        height: 60px;
    }

    .primary-btn-main-user .comman-primary-btn,
    .primary-btn-main-user .comman-primary-btn:hover,
    .primary-btn-main-user .comman-primary-btn.active,
    .primary-btn-main-user .comman-primary-btn:focus,
    .outline-btn-main .outline-btn-list,
    .blue-border-btn-main .blue-outline-btn {
        border-radius: 10px !important;
        font-size: 14px !important;
        line-height: 20px !important;
        min-height: 40px !important;
    }

    .home-text-content-top .home-pd-contnet {
        min-height: 550px
    }

    .home-text-content-top,
    .home-inner-main {
        min-height: 650px;
    }

    .home-content-details .home-start-your-fitness {
        font-size: 48px;
        line-height: 60px;
        min-height: initial;
        margin-bottom: 15px;
    }

    .title-details-joyful .joyful-spirit-title {
        font-size: 40px;
        line-height: 46px;
        /* max-width: 492px; */
        margin: auto auto 57px auto;
    }

    .virtual-box-list .customize-joyful-details {
        font-size: 14px;
        line-height: 22px;
    }

    .center-list-header .header-ul-list .header-listitem-li {
        padding: 10px 15px;
    }

    .outline-btn-main .outline-btn-list .store-img-home {
        margin-right: 10.1px;
    }

    .step-home-list .step-listitem-main .main-step-list-box {
        padding: 7px;
        min-width: 164px;
    }

    .step-home-list .step-listitem-main::after {
        right: 5px;
    }

    .virtual-joyful-race-main .virtual-joyful-list .virtual-joyful-listitem {
        margin-bottom: 15.5px;
    }

    .virtual-joyful-race-main .virtual-joyful-list {
        margin-top: 15.5px;
    }

    .joyful-bg-top {
        top: -40px;
    }

    .joyful-race-content,
    .img-column-bg {
        display: none;
        /* max-width: 267px;
        height: 377px;
        margin-left: -15px; */
    }

    .jyoful-main-content .pd-joyful-investment {
        padding: 70px 0px 70px 0px;
    }

    .roadmap-pd-list {
        padding: 70px 0px 297px 0px;
    }

    .refrrel-icon {
        width: 95px;
        height: 95px;
    }

    .flex-referral {
        margin-bottom: 5px;
        /* flex-direction: column; */
    }

    .referral-details-list .referral-details-card {
        padding: 5px 0px;
    }

    .title-reffrel-program .title-pro-refreel {
        font-size: 18px;
    }

    .title-reffrel-program .prices-pro-refreel {
        font-size: 16px;
        line-height: 23px;
    }

    .referral-content-details .referral-details-card {
        font-size: 10px;
        line-height: 21px;
    }

    .min-height-255px,
    .min-height-625px {
        min-height: initial;
    }

    .referral-friend-content {
        padding-top: 35px;
    }

    .refrral-friend-bg .refrral-mobile-img {
        max-width: 330.11px;
        height: 502px;
    }

    .refrral-friend-bg {
        margin: auto 22px auto 40px;
        max-width: 321.11px;
    }

    .referral-friend-content::after {
        width: 311px;
        height: 693px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .referral-details-main {
        padding: 70px 0px 70px 0px;
    }

    .referral-main-title-box .small-referral-title {
        font-size: 40px;
        line-height: 45px;
        margin-top: -15px;
    }

    .roadmap-title-content .title-roadmap-details,
    .contact-us-details .contactus-title {
        font-size: 40px;
        line-height: 45px;
        margin-top: -15px;
    }

    .referral-main-title-box .shadow-referral-title,
    .roadmap-title-content .shadow-contnet-title-comman,
    .contact-us-details .contactus-title-shadow {
        font-size: 50px;
        line-height: 55px;
        letter-spacing: 4px;
    }

    .roadmap-title-content .title-roadmap-details {
        margin-bottom: 52.7px;
    }

    .svg-rounded-border .footer-line-bg {
        display: none;
    }

    .rounde-footer-inner-details {
        max-width: initial;
        margin: 0px;
    }

    .footer-details-main .footer-title {
        line-height: 26px;
        font-size: 24px;
    }

    .footer-details-main .footer-desc {
        font-size: 11px;
        line-height: 19px;
    }

    .footer-details-main .footer-desc {
        font-size: 11px;
        line-height: 19px;
        margin-bottom: 15px;
    }

    .footer-form-details .footer-grid .footer-column {
        margin-bottom: 15px;
    }

    .address-footer-main {
        margin-bottom: 20px;
    }

    .footer-pos-main {
        padding-top: 20px;
    }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 899px) and (orientation : landscape) {
    .input-link-main .history-link-control input {
        min-width: 419px;
    }

    .table-refferal-main .refferal-main-paper-table {
        max-width: calc(100vw - 396px);
    }

    .login-user-main .login-pd-main,
    .login-pd-main {
        height: max-content !important;
    }

    .login-logo-details .logo-login {
        height: 70px;
    }

    .referral-friend-content::after {
        right: -9%;
    }

    .home-content-details .home-start-your-fitness {
        font-size: 44px;
        line-height: 55px;
        max-width: 550px;
    }

    .step-home-list .step-listitem-main .main-step-list-box {
        padding: 7px;
        min-width: 146px;
    }

    .step-home-list .step-listitem-main .main-step-list-box .title-step-home-list,
    .step-home-list .step-listitem-main .main-step-list-box .title-step-home-coming {
        font-size: 14px;
        line-height: 19px;
    }

    .btn-list-store .outline-btn-main .outline-btn-list {
        padding: 5px 20px;
        min-width: 142px;
        font-size: 11px;
        border-radius: 10px;
    }
}

@media screen and (min-width: 599px) and (max-width: 658px) {
    .road-content-max-list {
        max-width: 326px;
    }

    .rounded-img {
        width: 100%;
    }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 899px) and (orientation : landscape) {
    .table-refferal-main .refferal-main-table {
        min-width: 700px;
    }

    .table-refferal-main .refferal-main-paper-table {
        max-width: calc(100vw - 166px);
    }

}

@media screen and (max-width:599px) {
    .text-height-drop .text-drop-list {
        min-width: 62px;
        justify-content: center;
    }

    .text-weight-main .text-weight-list {
        min-width: 62px;
        justify-content: center;
    }


    .stepper-signup-column .height-stepper-main {
        margin-bottom: 13px;
    }

    .table-refferal-main .refferal-main-table {
        min-width: 560px;
    }

    .table-refferal-main .refferal-main-paper-table {
        max-width: calc(100vw - 60px);
    }

    .refferal-program-component {
        padding: 30px 15px;
    }

    .profile-step-details {
        display: flex;
        margin-bottom: 15px;
    }

    .btn-history-list {
        margin-right: 0px;
        text-align: end;
        margin-top: 10px;
    }

    .history-link-control,
    .input-link-main,
    .btn-history-list {
        width: 100%;
    }

    .input-link-main .history-link-control input {
        padding: 10px 20px 10px;
        min-width: initial;
        width: 100%;
        max-width: 100%;
        font-size: 11px;
        margin-right: 0px;
    }

    .link-main-content {
        flex-direction: column;
        align-items: start;
    }

    .profile-step-details .steper-box-profile {
        height: 35px;
        width: 35px;
        font-size: 13px;
    }

    .profile-tab-box .profile-stepper-amin {
        margin-bottom: 15px;
        flex-direction: column;
    }

    .profile-step-details .steper-box-profile {
        margin: initial;
    }

    .profile-step-details .profile-steplabel-main {
        max-width: initial;
        margin-top: 7px;
        margin-left: 20px;
    }

    .profile-stepper-amin::after {
        width: 1px;
        height: calc(100% - 60px);
        left: 5%;
    }

    .fitness-goal-flex {
        width: calc(100% / 1);
    }

    .step-four-account-details .title-health-challenges,
    .step-third-account-details .title-health-challenges {
        font-size: 16px;
    }

    .referral-content-details {
        width: 100%;
    }

    .flex-referral {
        margin-bottom: 5px;
        /* flex-direction: column; */
    }

    .referral-details-list .referral-details-card {
        padding: 5px 0px;
    }

    .title-reffrel-program .title-pro-refreel {
        font-size: 18px;
    }

    .title-reffrel-program .prices-pro-refreel {
        font-size: 16px;
        line-height: 23px;
    }

    .referral-content-details .referral-details-card {
        font-size: 12px;
        line-height: 21px;
    }

    .min-height-255px,
    .min-height-625px {
        min-height: initial;
    }

    /*  */
    .step-home-list .step-home-register {
        padding: 0px 0px 20px 0px;
        flex-direction: column;
        max-width: 141px;
    }

    .step-home-list .step-listitem-main {
        margin-bottom: 32px;
    }

    .step-home-list .step-listitem-main .main-step-list-box {
        padding: 6px;
        border-radius: 8px;
        min-width: 141px;
    }

    .step-home-list .step-listitem-main .main-step-list-box .title-step-home-coming,
    .step-home-list .step-listitem-main .main-step-list-box .title-step-home-list {
        font-size: 11px;
        line-height: 13px;
    }

    .step-home-list .step-listitem-main::after {
        height: 29px;
        width: 29px;
        right: 0;
        left: 0;
        margin: auto;
        bottom: -30px;
        transform: rotate(89deg);
    }

    .home-content-details .home-choose-life-title {
        font-size: 22px;
        line-height: 29px;
        margin-bottom: 7px;
    }

    .home-text-content-top .home-pd-contnet {
        padding: 30px 0px 30px 0px;
    }

    .home-content-details .home-distracted-long {
        flex-direction: column;
        align-items: start;
        font-size: 12px;
        margin-bottom: 15px;
    }

    .home-content-details .home-start-your-fitness {
        font-size: 31px;
        line-height: 40px;
        /* max-width: 249px; */
        margin-bottom: 15px;
        min-height: initial;
    }

    .outline-btn-main .outline-btn-list {
        min-height: 34px;
        font-size: 9px;
        padding: 3px 10px;
        justify-content: start;
        min-width: initial;
        border-radius: 9px;
    }

    .step-home-list .step-listitem-main:last-child {
        margin-bottom: 0px;
    }

    /*  */
    .signup-scroll-form {
        max-height: initial;
        padding-right: 0px;
        overflow: initial;
    }

    .mr-hgt-scroll {
        margin-right: 0px;
    }

    .outline-btn-main .outline-btn-list {
        min-height: 38px;
    }

    .wallet-btn-main .outline-btn-main .outline-btn-list {
        min-height: 28px;
        height: initial;
    }

    .roadmap-details-content .roadmap-desc {
        max-width: initial;
    }

    .max-content-roadmap {
        max-width: 361.89px;
    }

    .login-pd-main {
        padding: 30px 15px;
        /* height: calc(100vh - 20vh); */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: max-content;
    }

    .login-user-main .login-pd-main {
        padding: 30px 15px;
        height: calc(100vh - 20vh);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .login-bg-content,
    .signup-bg-content {
        height: 20vh;
        padding: 15px 0px;
        background-image: none;
        background-color: #032058;
    }

    .flex-check-login {
        margin-top: 10px;
    }

    .login-details-title .login-title {
        font-size: 50px;
        line-height: 60px;
    }

    .mb-20px {
        margin-bottom: 10px;
    }

    .login-form-details .login-max-content {
        max-width: initial;
        margin: initial;
        width: 100%;
    }

    .login-logo-details .logo-login {
        height: 75px;
    }

    .title-details-joyful .joyful-spirit-title {
        font-size: 32px;
        line-height: 40px;
        margin: auto auto 35px auto;
    }

    .max-content-roadmap .list-roadmap-details::after,
    .list-roadmap-details .listitem-roadmap-details:after,
    .bottom-arrow-roadmap .roadmap-arrow-bottom,
    .roadmapbg-main-contant::after,
    .referral-main-title-box .shadow-referral-title,
    .roadmap-title-content .shadow-contnet-title-comman,
    .svg-rounded-border .footer-line-bg,
    .contact-us-details .contactus-title-shadow {
        display: none;
    }

    .rounde-footer-inner-details {
        max-width: initial;
        margin: initial;
    }

    .list-roadmap-details .listitem-roadmap-details {
        padding: 0px 0px 35.1px 0px;
    }

    .list-roadmap-details .listitem-roadmap-details:nth-child(even) .road-content-max-list .rounded-road-year-flex {
        flex-direction: column-reverse;
        align-items: end;
    }

    .road-content-max-list .rounded-road-year-flex {
        align-items: start;
        flex-direction: column-reverse;
    }

    .rounded-road-year-flex .year-text-contnet {
        margin-right: 0px;
    }

    .bg-year-rounded img {
        width: 100%;
    }

    .list-roadmap-details .listitem-roadmap-details:nth-child(even) .roadmap-details-content {
        margin: 0px 0px 0px 0px;
        max-width: inherit;
        padding-left: 0px;
    }

    .virtual-joyful-race-main .virtual-joyful-list {
        padding: 10px 0px 0px 0px;
    }

    .roadmap-details-content {
        max-width: inherit;
        margin: 0px 0px 0px 0px;
    }

    .roadmap-pd-list {
        padding: 50px 0px 50px 0px;
    }

    .referral-details-main {
        padding: 50px 0px 80px 0px;
    }

    .rounded-road-year-flex .qtitle-roadmap {
        margin-bottom: -17px;
    }

    .list-roadmap-details .listitem-roadmap-details:nth-child(even) .rounded-road-year-flex .year-text-contnet {
        margin-left: 0px;
    }

    .roadmap-title-content .title-roadmap-details,
    .referral-main-title-box .small-referral-title,
    .contact-us-details .contactus-title {
        font-size: 40px;
        margin-bottom: 20.7px;
        line-height: 50px;
        margin-top: -16px;
    }

    .title-referral-main .description-referral-details {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 23px;
    }

    .title-reffrel-program .prices-pro-refreel {
        font-size: 14px;
        line-height: 18px;
    }

    .title-reffrel-program .title-pro-refreel {
        font-size: 18px;
        line-height: 24px;
    }


    .flex-referral {
        margin-bottom: 15px;
    }

    img.refrrel-icon {
        width: 80px;
        height: 80px;
    }

    .referral-friend-content {
        padding-top: 25px;
    }

    .joyful-race-content {
        width: 100%;
        height: 465px;
    }

    .jyoful-main-content .pd-joyful-investment {
        padding: 50px 0px 50px 0px;
    }

    .virtual-joyful-race-main .virtual-joyful-list .virtual-joyful-listitem {
        margin-bottom: 15px;
    }

    .virtual-box-list .customize-joyful-details {
        font-size: 18px;
        line-height: 28px;
        max-width: initial;
    }

    .pos-relative-joyful {
        display: flex;
        flex-direction: column-reverse;
    }

    .joyful-bg-top {
        position: initial;
    }

    .logo-main-left,
    .logo-header {
        height: 55px;
    }

    .primary-btn-main-user .comman-primary-btn,
    .blue-border-btn-main .blue-outline-btn {
        border-radius: 10px !important;
        min-height: 38px !important;
        font-size: 14px !important;
    }

    .home-text-content-top .home-pd-contnet {
        min-height: 485px;
    }

    .home-text-content-top {
        min-height: 600px;
        padding-top: 75px;
    }

    .home-inner-main {
        min-height: 600px;
        object-position: left;
    }

    .hero-pos-relative {
        margin-top: -75px;
    }

    .referral-friend-content::after {
        bottom: -39px;
        right: -44%;
        width: 437px;
        height: 480px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        top: initial;
    }

    .refrral-friend-bg .refrral-mobile-img {
        max-width: 330.11px;
        height: 388px;
        margin-top: 52px;
    }

    .refrral-friend-bg {
        max-width: 300.11px;
        margin-left: auto;
        margin-right: initial;
    }

    .footer-logo {
        height: 100px;
        max-width: initial;
        text-align: center;
        margin: 0px 0px 15px 0px;
    }

    .address-footer-main .footer-add-ul .footer-add-li {
        justify-content: center;
        padding: 0px 0px 4px 0px;
    }

    .footer-bg-rounded,
    .footer-social-icon-main .footer-social-icon-ul {
        align-items: start;
        justify-content: start;
    }

    .footer-bg-rounded {
        height: initial;
        margin-bottom: 40px;
        justify-content: center;
    }

    .address-footer-main {
        margin-bottom: 15px;
    }

    .footer-details-main .footer-desc {
        font-size: 11px;
        line-height: 19px;
        margin-bottom: 26px;
        text-align: center;
    }

    .footer-details-main .footer-title {
        line-height: 35px;
        font-size: 25px;
        margin-bottom: 20px;
        text-align: center;

    }

    .modal-btn-ok .comman-primary-btn {
        min-width: 112px;
    }

    .modal-delete .delete-modal-inner-main {
        padding: 30px 15px;
        width: calc(100% - 30px);
    }
    .delete-modal-inner-main .delete-modal-title {
        font-size: 17px;
        line-height: 27px;
    }

}

@media only screen and (max-width: 576px) {

    /* .year-text-contnet .year-title-details {
        height: 79px;
        width: 79px;
    } */
    .rounded-road-year-flex .year-text-contnet {
        width: 100%;
    }

    .roadmap-details-content {
        margin: 0px 0px 0px 0px;
    }

    .list-roadmap-details .listitem-roadmap-details:nth-child(even) .roadmap-details-content {
        margin: 0px 0px 0px 0px;
        max-width: inherit;
        padding-left: 0px;
    }

    .social-icon-img {
        height: 25px;
        object-fit: contain;
        width: 25px;
    }
}

@media screen and (min-width: 375px) and (max-width:415px) {

    .year-text-contnet .year-title-details {
        height: 81px;
        width: 73px;
    }

    .bg-year-rounded img {
        width: 100%;
        height: 80.41px;
    }

    /* .account-link-details{
        padding-bottom: 30px;
    } */
}


@media screen and (min-width: 320px) and (max-width:374px) {

    .outline-btn-main .outline-btn-list {
        padding: 5px 10px;
        min-width: 141px;
        font-size: 13px;
    }

    .footer-social-icon-main .footer-social-icon-ul {
        max-width: 216.82px;
    }

    .footer-social-icon-main .visit-social-title {
        font-size: 17px;
        margin-bottom: 10.3px;
    }

    .footer-add-icons {
        height: 13px;
    }

    .address-footer-main .footer-add-ul .footer-add-li .add-icon-main-footer {
        height: 36px;
        width: 36px;
    }

    .footer-pos-main {
        padding-top: 18px;
    }

    .address-footer-main .footer-add-ul .footer-add-li .add-footer-title {
        font-size: 12px;
    }

    .referral-content-details .referral-details-card {
        font-size: 13px;
        line-height: 22px;
    }

    .joyful-race-content {
        width: 100%;
        height: 455px;
    }

    .virtual-box-list .customize-joyful-details {
        font-size: 15px;
        line-height: 24px;
        max-width: initial;
    }

    .virtual-joyful-race-main .virtual-joyful-list {
        padding: 10px 0px 0px 0px;
    }

    .year-text-contnet .year-title-details {
        height: 68px;
        width: 64px;
        font-size: 14px;
        padding: 0px;
    }
}

@media screen and (min-width: 280px) and (max-width: 319px) {
    .modal-btn-ok .comman-primary-btn {
        min-width: 72px;
    }

    .flex-center-radio-hgt {
        flex-direction: column;
        align-items: start;
    }

    .dropdown-select .MuiSelect-select {
        padding: 9px 16px !important;
    }

    .height-radio-main .form-heightgroup-control-main {
        padding-left: 10px;
        margin-top: 6px;
    }

    .step-home-list .step-home-register {
        padding: 0px 0px 20px 0px;
        flex-direction: column;
        max-width: 141px;
    }

    .text-height-drop .text-drop-list {
        min-height: 41.7px;
        padding: 6px 10px;
    }

    .text-weight-main .text-weight-list {
        min-height: 33px;
        padding: 8.5px 10px;
        min-width: 62px;
    }

    .step-home-list .step-listitem-main {
        margin-bottom: 32px;
    }

    .step-home-list .step-listitem-main::after {
        height: 29px;
        width: 29px;
        right: 0;
        left: 0;
        margin: auto;
        bottom: -30px;
        transform: rotate(89deg);
    }

    .home-content-details .home-distracted-long {
        flex-direction: column;
        align-items: start;
        font-size: 12px;
        margin-bottom: 15px;
    }

    .home-text-content-top .home-pd-contnet {
        padding: 30px 0px 30px 0px;
    }

    .home-content-details .home-distracted-long .home-join-text {
        margin-left: 0px;
    }

    .step-home-list .step-listitem-main:last-child {
        margin-bottom: 0px;
    }

    .step-home-list .step-listitem-main .main-step-list-box .title-step-home-coming,
    .step-home-list .step-listitem-main .main-step-list-box .title-step-home-list {
        font-size: 11px;
        line-height: 13px;
    }

    .step-home-list .step-listitem-main .main-step-list-box {
        padding: 6px;
        border-radius: 8px;
        min-width: 141px;
    }

    .outline-btn-main .outline-btn-list {
        min-height: 34px;
        font-size: 9px;
        padding: 3px 10px;
        justify-content: start;
        min-width: initial;
        border-radius: 9px;
    }

    .primary-btn-main-user .comman-primary-btn,
    .blue-border-btn-main .blue-outline-btn {
        border-radius: 8px !important;
        min-height: 33px !important;
        font-size: 11px !important;
        padding: 3px 15px !important;
    }

    .burgur-icon-main .outline-btn-list {
        padding: 2px 11px;
        border-radius: 8px;
        height: 33px !important;
        min-height: 33px;
    }

    .logo-main-left,
    .logo-header {
        height: 50px;
    }

    .home-content-details .home-start-your-fitness {
        font-size: 22px;
        line-height: 32px;
        max-width: 249px;
        margin-bottom: 15px;
        min-height: initial;
    }

    .home-content-details .home-choose-life-title {
        font-size: 22px;
        line-height: 29px;
        margin-bottom: 7px;
    }

    .login-logo-details .logo-login {
        height: 75px;
    }

    .login-details-title .login-title {
        font-size: 34px;
        line-height: 48px;
    }

    .login-details-title .login-desc {
        font-size: 12px;
        line-height: 18px;
    }

    .checkbox-main span {
        font-size: 12px;
        line-height: 16px;
    }

    .create-account-text .signup-redirect {
        font-size: 12px;
        line-height: 12px;
        display: block;
    }

    .form-group .form-control input {
        padding: 8.45px 16px;
    }

    .flex-check-login {
        margin-bottom: 10px;
    }

    .title-details-joyful .joyful-spirit-title {
        font-size: 25px;
        line-height: 34px;
        margin: auto auto 24px auto;
    }

    .joyful-race-content {
        width: 100%;
        height: 318px;
    }

    .bg-virtual-icon-main .icon-joy-main {
        height: 35px;
        width: 35px;
    }

    .bg-virtual-icon-main {
        height: 70px;
        width: 70px;
    }

    .customize-joyful-details {
        font-size: 13px;
        line-height: 18px;
    }

    .year-text-contnet .year-title-details {
        height: 72px;
        width: 52px;
        font-size: 13px;
        line-height: 29px;
        padding: 9px;
    }

    .rounded-road-year-flex .qtitle-roadmap {
        font-size: 18px;
        line-height: 21px;
    }

    .referral-friend-content::after {
        right: -84%;
    }

    .footer-add-icons {
        height: 13px;
    }

    .address-footer-main .footer-add-ul .footer-add-li .add-icon-main-footer {
        height: 35px;
        width: 35px;
    }

    .address-footer-main .footer-add-ul .footer-add-li .add-footer-title {
        font-size: 10px;
    }

    .footer-logo {
        height: 75px;
    }

    .footer-social-icon-main .footer-social-icon-ul .footer-social-icon-li .social-redirect-link {
        height: 33.21px;
        width: 33.21px;
    }

    .footer-social-icon-main .footer-social-icon-ul {
        max-width: 211.82px;
    }

    .footer-details-main .footer-title {
        font-size: 21px;
    }

    .footer-form-details .footer-grid .footer-column {
        margin-bottom: 15px;
    }

    .bottom-bg-footer .pd-footer-copy {
        padding: 14px 0px 15px 0px;
    }

    .profile-step-details .profile-steplabel-main {
        max-width: initial;
        margin-top: 7px;
        margin-left: 20px;
        text-align: start;
        font-size: 13px;
    }

    .wallet-btn-main .outline-btn-main .outline-btn-list {
        min-height: 23px !important;
        display: flex;
        align-items: center;
        line-height: 10px;
        height: initial;
    }
}

/* @media only screen and (min-device-width: 279px) and (max-device-width: 654px) and (orientation : landscape){
   body{background-color: red;}
} */

@media (max-width:768px) {
    .episap-modal-box {
        min-width: auto;
    }
}