@media (max-width: 1023px) {
    .admin-main .auth-main {
        grid-template-columns: 1fr;
        overflow: auto;
    }

    .admin-main .auth-main .auth-right {
        padding-top: 0;
        height: unset;
        overflow: unset;
    }

    .admin-main .auth-main .auth-left {
        padding-bottom: 0;
    }

    .admin-main .auth-main .auth-left .company-logo {
        max-width: 400px;
    }

}

@media (max-width: 767px) {
    .admin-main .auth-main .auth-left .company-logo {
        max-width: 150px;
    }

    .admin-main .auth-main::after {
        width: 150px;
        height: 150px;
    }

    .admin-main .auth-main::before {
        width: 250px;
        height: 250px;
    }
}