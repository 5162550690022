:root {
  --black: #000;
  --white: #fff;
  --background: #f7fafe;
  --primary: #27447C;
  --secondary: #e4e4e4;
  --radius12: 12px;
  --radius6: 6px;
  --shadow-sm: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

.p-0 {
  padding: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.h-40 {
  min-height: 40px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.pr-30 {
  padding-right: 30px;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-end {
  justify-content: end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}
.align-items-center{
  align-items: center!important;
}
.fw-bold {
  font-weight: 700 !important;
}

/* Wallet css */



.crtl-wrape {
  background: linear-gradient(99deg, #537fab, #4845ff);
  padding: 1px;
  border-radius: 15px;
  margin: 20px;
}


.crtl-wrape .inner-pad2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--white);
  height: 100%;
  width: 100%;
  padding: 15px;
  border-radius: 14px;
}

.crtl-wrape .inner-pad2 p.mint-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.crtl-wrape .inner-pad2 p {
  font-family: var(--head-text);
  font-size: 17px;
  margin-bottom: 5px;
}

.crtl-wrape .inner-pad2 p.Mui-error {
  font-size: 13px;
  color: red;
}

.home-input-main .form-control input {
  border: 0px !important;
  text-align: left;
  font-size: 20px !important;
  line-height: 25px !important;
  color: #fff !important;
}

.home-input-main .form-control input::placeholder {
  font-size: 20px !important;
  line-height: 25px !important;
  color: #fff !important;
}

.home-input-main .form-control fieldset {
  border: 0px !important;
}

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background: var(--blue);
  border-radius: 20px;
  width: 100%;
  color: var(--white);
  font-size: 14px;
  line-height: 16px;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: 400;
}

.input-fild {
  padding: 0 !important;
  height: 42px;
  width: 90%;
  color: black !important;
  border-radius: 8px;
  border: 1px solid white !important;
  margin-bottom: 23px !important;
}

.input-fild input {
  padding: 9px 10px;
  font-size: 15px;
}
