/* roadMap editor start */
.ck.ck-balloon-panel.ck-powered-by-balloon
  .ck.ck-powered-by
  .ck-powered-by__label {
  display: none;
}

.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by .ck-icon {
  display: none;
}

.jodit-workplace + .jodit-status-bar:not(:empty) {
}

/* roadMap editor end */

/* .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
    align-items: center;
    justify-content: center;
} */

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

body,
html {
  scroll-behavior: smooth;
  padding-right: 0px !important;
  /* overflow: auto !important; */
}

.container {
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/poppins-bold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Teko-Regular";
  src: url("../fonts/Teko/Teko-Regular.ttf");
}

@font-face {
  font-family: "Teko-Bold";
  src: url("../fonts/Teko/Teko-Bold.ttf");
}

@font-face {
  font-family: "Teko-Medium";
  src: url("../fonts/Teko/Teko-Medium.ttf");
}

@font-face {
  font-family: "Teko-SemiBold";
  src: url("../fonts/Teko/Teko-SemiBold.ttf");
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.primary-btn-main-user .comman-primary-btn,
.primary-btn-main-user .comman-primary-btn:hover,
.primary-btn-main-user .comman-primary-btn.active,
.primary-btn-main-link-user .comman-primary-btn,
.primary-btn-main-link-user .comman-primary-btn:hover,
.primary-btn-main-link-user .comman-primary-btn.active {
  background: transparent linear-gradient(270deg, #f5aa16 0%, #e17025 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  padding: 5px 30px;
  min-height: 45px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  text-transform: capitalize;
  box-shadow: none;
  text-decoration: none;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

.form-group .form-control input {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 11.45px 16px;
  background-color: transparent;
  border-radius: 14px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}

.otp-input-mui .otp-inner-input-list input {
  border: 1px solid #a6ccff;
  padding: 11.45px 16px;
  background-color: transparent;
  border-radius: 14px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.form-group .text-custom-control {
  width: 100%;
  min-height: 138px;
  background: transparent;
  padding: 11.45px 16px;
  border-radius: 14px;
  font-family: "Poppins-Regular";
  border: 1px solid #a6ccff;
  resize: none;
}

.form-group .form-control input::placeholder {
  color: #6b7280;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #a6ccff;
  border-width: 0px;
}

fieldset {
  border-color: transparent !important;
  top: 0px !important;
}

.footer-main .custom-group .form-label,
.footer-main .form-group .form-control input::placeholder,
.footer-main .form-group .form-control input,
.footer-main .form-group .text-custom-control {
  color: #ffff;
}

/* home start */

.hero-pos-relative {
  position: relative;
  margin-top: -100px;
}

.home-inner-main {
  min-height: 802px;
  width: 100%;
  object-fit: cover;
}

.home-text-content-top .home-pd-contnet {
  padding: 50px 0px 50px 0px;
  min-height: 702px;
  display: flex;
  align-items: center;
}

.home-text-content-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 802px;
  padding-top: 100px;
  display: flex;
  align-items: center;
}

.home-content-details .home-choose-life-title {
  font-size: 32px;
  font-family: "Teko-Bold";
  color: #f5aa16;
  line-height: 45px;
  margin-bottom: 10px;
}

.home-content-details .home-start-your-fitness {
  color: #ffffff;
  max-width: 630px;
  font-size: 56px;
  font-family: "Teko-SemiBold";
  line-height: 68px;
  text-transform: uppercase;
  min-height: 160px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.home-content-details .home-distracted-long {
  color: #ffffff;
  font-size: 14px;
  font-family: "Poppins-Regular";
  line-height: 28px;
  display: flex;
  align-items: center;
  margin-bottom: 33px;
}

.home-content-details .home-distracted-long .home-join-text {
  margin-left: 10px;
}

.outline-btn-main .outline-btn-list {
  border: 1px solid #ffffff;
  border-radius: 12px;
  padding: 5px 20px;
  min-height: 45px;
  color: #ffffff;
  font-family: "Poppins-Medium";
  text-transform: capitalize;
  min-width: 151px;
  font-size: 15px;
}

.store-main-btn-list {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.home-register-btn .primary-btn-main-user .comman-primary-btn {
  box-shadow: 0px 3px 6px #00000047;
}

.outline-btn-main .outline-btn-list .store-img-home {
  /* margin-right: 4.1px; */
  margin-right: 6.1px;
}

.store-main-btn-list .btn-list-store {
  margin-right: 10px;
}

/* home end */

/* header start */
.comman-flex-justify {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-main-details {
  background: rgb(0 0 0 / 48%) 0% 0% no-repeat padding-box;
}

.header-main-component {
  z-index: 9;
  position: relative;
}

.header-main-pd {
  padding: 10px 0px;
}

.logo-main-left,
.logo-header {
  height: 80px;
}

.center-list-header .header-ul-list {
  display: flex;
  align-items: center;
  padding: 0px;
}

.center-list-header .header-ul-list .header-listitem-li {
  padding: 0px 20px;
  max-width: max-content;
}

.center-list-header .header-ul-list .header-listitem-li .header-redirect-link {
  text-decoration: none;
  font-size: 16px;
  font-family: "Poppins-Regular";
  color: #ffffff;
  line-height: 23px;
}

/* header end */

/* joyful start */

.jyoful-main-content .pd-joyful-investment {
  padding: 60px 0px 159px 0px;
}

.title-details-joyful .joyful-spirit-title {
  font-size: 47px;
  line-height: 66px;
  max-width: 872px;
  margin: auto auto 67px auto;
  font-family: "Teko-SemiBold";
  text-align: center;
  color: #06060f;
  text-transform: uppercase;
}

.virtual-joyful-race-main .virtual-joyful-list .virtual-joyful-listitem {
  padding: 0px 0px 13.5px 0px;
  position: relative;
  margin-bottom: 13.5px;
  /* margin-bottom: 57.5px; */
  align-items: start;
  /* flex-direction: column; */
}

.virtual-joyful-race-main .virtual-joyful-list .virtual-joyful-listitem::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: #051f58;
  bottom: 0;
}

.customize-joyful-details span {
  color: #e17025;
  font-family: "Poppins-SemiBold";
}

.customize-joyful-details {
  font-size: 13px;
  line-height: 20px;
  color: #1b2636;
  font-family: "Poppins-SemiBold";
  max-width: inherit;
  margin: 0px;
}

.virtual-joyful-race-main
  .virtual-joyful-list
  .virtual-joyful-listitem:last-child {
  margin-bottom: 0px;
}

.joyful-race-main-img {
  text-align: center;
}

.joyful-bg-top {
  position: absolute;
  top: -40px;
  /* top: -70px; */
  left: 0;
  right: 0;
}

.pos-relative-joyful,
.referral-friend-content,
.footer-pos-main {
  position: relative;
}

.joyful-race-content {
  max-width: 553px;
  height: 629px;
  margin: auto;
  object-fit: contain;
}

.virtual-joyful-race-main {
  z-index: 1;
  position: relative;
}

/* joyful end */

/* Roadmap start */

.roadmapbg-main-contant {
  background-image: linear-gradient(
      180deg,
      rgb(3 32 88 / 77%) 0%,
      rgb(0 0 0 / 77%) 100%
    ),
    url("../images/png/roadmapbg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.roadmap-pd-list {
  padding: 79.5px 0px 474px 0px;
}

.roadmap-title-content .shadow-contnet-title-comman {
  font-size: 89px;
  font-family: "Poppins-Bold";
  text-transform: uppercase;
  text-align: center;
  background: -webkit-linear-gradient(rgb(255 255 255 / 25%) -3%, #092257);
  /* background: -webkit-linear-gradient(rgb(255 255 255 / 25%) 22%, rgb(9 30 72 / 37%)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.28;
  line-height: 86px;
  letter-spacing: 15px;
}

.rounded-img {
  width: 100%;
  height: 73.41px;
}

.list-roadmap-details .listitem-roadmap-details:last-child {
  padding-bottom: 0px;
}

.bottom-arrow-roadmap .roadmap-arrow-bottom {
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.roadmap-title-content .title-roadmap-details {
  color: #fff;
  font-family: "Teko-SemiBold";
  font-size: 60px;
  text-align: center;
  text-transform: uppercase;
  line-height: 66px;
  margin-bottom: 142.7px;
  margin-top: -21px;
}

.max-content-roadmap {
  max-width: 631.89px;
  margin: auto;
}

.road-content-max-list .rounded-road-year-flex,
.flex-btn {
  display: flex;
  align-items: center;
}

.rounded-road-year-flex .year-text-contnet {
  position: relative;
  margin-right: 12.3px;
  z-index: 9;
}

.year-text-contnet .year-title-details {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  display: flex;
  justify-content: center;
  height: 73px;
  width: 73px;
  align-items: center;
  font-size: 21px;
  font-family: "Poppins-Medium";
  color: #ffff;
  line-height: 29px;
  padding: 9px;
}

.rounded-road-year-flex .qtitle-roadmap {
  font-size: 23px;
  font-family: "Poppins-Medium";
  color: #fff;
  line-height: 32px;
}

.rounded-road-year-flex .qtitle-roadmap {
  font-size: 23px;
  font-family: "Poppins-Medium";
  color: #fff;
  line-height: 32px;
}

.list-roadmap-details .listitem-roadmap-details:nth-child(even) {
  margin-left: auto;
  justify-content: end;
}

.roadmap-details-content .roadmap-desc {
  font-size: 12px;
  font-family: "Poppins-Regular";
  line-height: 18px;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  max-width: 234px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* .roadmap-details-content {
    max-width: 231px;
    margin: -18px 44px 0px 44px;
} */

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .road-content-max-list
  .rounded-road-year-flex {
  flex-direction: row-reverse;
}

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .year-text-contnet
  .year-title-details {
  left: 0;
  margin-right: auto;
  margin-left: initial;
}

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .rounded-road-year-flex
  .year-text-contnet {
  margin-right: 0px;
  margin-left: 12.3px;
}

.list-roadmap-details .listitem-roadmap-details {
  /* padding: 0px 0px 65.1px 0px; */
  padding: 0px 0px 45.1px 0px;
  position: relative;
}

/* .list-roadmap-details .listitem-roadmap-details:nth-child(even) .roadmap-details-content {
    margin: -18px auto 0px 130px;
} */

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .bg-year-rounded
  .rounded-img {
  transform: rotate(180deg);
}

.max-content-roadmap .list-roadmap-details {
  padding: 0px;
  position: relative;
  z-index: 1;
}

/* .max-content-roadmap .list-roadmap-details::after {
    content: '';
    background: #fff;
    height: calc(100% - 124px);
    width: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
} */

/* referral start */

.referral-main-title-box .small-referral-title {
  font-size: 60px;
  text-align: center;
  font-family: "Teko-SemiBold";
  text-transform: uppercase;
  line-height: 84px;
  margin-bottom: 8px;
  color: #000000;
  margin-top: -21px;
}

.title-referral-main .description-referral-details {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: center;
  margin-bottom: 52px;
}

.referral-friend-content {
  padding-top: 93px;
}

.flex-referral {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

img.refrrel-icon {
  width: 117px;
  height: 102px;
  object-fit: contain;
  margin-right: 13px;
}

.referral-content-details {
  border: 1px solid #a6ccff;
  border-radius: 16px;
  padding: 10px 10px 17px 10px;
}

.referral-card-list-details .referral-details-list {
  padding: 0px;
}

.referral-details-list .referral-details-card {
  padding: 10px 10px 9px 10px;
  border-bottom: 1px solid #a6ccff4f;
}

.referral-details-list .referral-details-card:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

.title-reffrel-program .title-pro-refreel {
  font-size: 22px;
  line-height: 31px;
  color: #000000;
  font-family: "Poppins-SemiBold";
  margin-bottom: 4px;
}

.title-reffrel-program .prices-pro-refreel {
  font-family: "Poppins-Medium";
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.referral-content-details .referral-details-card {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.referral-card-list-details .referral-box-ul,
.virtual-joyful-race-main .virtual-joyful-list,
.address-footer-main .footer-add-ul,
.footer-social-icon-main .footer-social-icon-ul {
  padding: 0px;
}

.referral-card-list-details .referral-box-ul .referral-box-listitem {
  padding: 0px 0px 20px 0px;
}

.referral-card-list-details .referral-box-ul .referral-box-listitem:last-child {
  padding-bottom: 0px;
}

.refrral-friend-bg .refrral-mobile-img {
  max-width: 330.11px;
  height: 608px;
  object-fit: cover;
}

.refrral-friend-bg {
  margin: auto 62px auto auto;
  max-width: 330.11px;
}

.referral-friend-content::after {
  content: "";
  background-image: url("../images/svg/reffrral-bg.svg");
  position: absolute;
  top: 0;
  right: 0;
  width: 471px;
  height: 813px;
  z-index: -1;
}

.referral-grid {
  align-items: center;
}

.referral-details-main {
  padding: 73.5px 0px 169px 0px;
  overflow-x: hidden;
}

/* footer start */

.footer-main-content {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/png/footerbg.png");
  background-position: center;
}

.pd-footer-details {
  padding: 64.5px 0px 104px 0px;
}

.contact-us-details .contactus-title {
  font-size: 60px;
  text-align: center;
  color: #ffff;
  line-height: 84px;
  text-transform: uppercase;
  font-family: "Teko-SemiBold";
  margin-bottom: 25px;
  margin-top: -21px;
}

.referral-main-title-box .shadow-referral-title {
  font-size: 85px;
  font-family: "Poppins-Bold";
  text-transform: uppercase;
  text-align: center;
  background: -webkit-linear-gradient(#e3e6eb -17%, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.45;
  line-height: 86px;
  letter-spacing: 14px;
}

.contact-us-details .contactus-title-shadow {
  font-size: 89px;
  font-family: "Poppins-Bold";
  text-transform: uppercase;
  text-align: center;
  background: -webkit-linear-gradient(rgb(255 255 255 / 25%) -3%, rgb(0 15 44));
  /* background: -webkit-linear-gradient(rgb(255 255 255 / 25%) 22%, rgb(9 30 72 / 37%)); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.28;
  line-height: 86px;
  letter-spacing: 15px;
}

.custom-group .form-label {
  font-size: 14px;
  color: #08080a;
  font-family: "Poppins-Medium";
  line-height: 20px;
  margin-bottom: 11px;
}

.footer-grid .footer-column {
  padding: 0px 14px;
}

.footer-form-details .footer-grid .footer-column {
  margin-bottom: 30px;
}

.footer-form-details .footer-grid .footer-column:last-child {
  margin-bottom: 0px;
}

.footer-grid {
  margin: 0px -14px;
}

.footer-details-main .footer-title {
  color: #ffffff;
  font-family: "Poppins-SemiBold";
  line-height: 49px;
  font-size: 35px;
  margin-bottom: 20px;
}

.footer-details-main .footer-desc {
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  font-family: "Poppins-Regular";
  margin-bottom: 26px;
}

/* .footer-bg-rounded {
    background-image: url('../images/svg/footer-line.svg');
    background-repeat: no-repeat;
    max-width: 557px;
    height: 557px;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
} */

.rounde-footer-inner-details {
  max-width: 356px;
  margin: auto auto auto 51px;
  position: relative;
  z-index: 9;
}

.footer-bg-rounded {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 437px;
}

.svg-rounded-border .footer-line-bg {
  max-width: 557px;
  height: 557px;
}

.svg-rounded-border {
  position: absolute;
  top: -56px;
  left: -60px;
}

.footer-social-icon-main .visit-social-title {
  font-size: 19px;
  color: #fff;
  font-family: "Poppins-SemiBold";
  margin-bottom: 18.3px;
  text-align: center;
  line-height: 27px;
}

.footer-logo {
  height: 118px;
  max-width: 168px;
  margin: auto auto 26px auto;
}

.address-footer-main .footer-add-ul .footer-add-li {
  padding: 0px 0px 8px 0px;
}

.address-footer-main .footer-add-ul .footer-add-li .add-icon-main-footer {
  height: 41px;
  width: 41px;
  background: #122341;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

.address-footer-main .footer-add-ul .footer-add-li .add-footer-title {
  font-family: "Poppins-Regular";
  font-size: 14px;
  color: #fff;
  line-height: 20px;
}

.address-footer-main {
  margin-bottom: 43px;
}

.footer-social-icon-main .footer-social-icon-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 243.82px;
  margin: auto;
}

.footer-social-icon-main .footer-social-icon-ul .footer-social-icon-li {
  padding: 0px;
  display: initial;
}

.footer-social-icon-main
  .footer-social-icon-ul
  .footer-social-icon-li
  .social-redirect-link {
  height: 42.21px;
  width: 42.21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.footer-top-minus {
  position: absolute;
  top: 0;
  z-index: 9;
  left: 0;
  bottom: 0;
  right: 0;
}

.footer-pos-main {
  padding-top: 53px;
}

.send-footer-btn .comman-primary-btn {
  min-width: 161px;
}

.signin-btn-main .comman-primary-btn {
  min-width: 100%;
}

.send-footer-btn {
  text-align: end;
  padding-top: 16px;
}

.bottom-bg-footer {
  background-color: #f5aa16;
}

.bottom-bg-footer .pd-footer-copy {
  padding: 19px 0px 18px 0px;
  text-align: center;
}

.bottom-bg-footer .copyright-title {
  font-size: 14px;
  line-height: 20px;
  color: #08090b;
  font-family: "Poppins-Medium";
  text-decoration: none;
}

/* login start */
.login-bg-content {
  background-image: linear-gradient(
      45deg,
      rgb(3 32 88 / 60%),
      rgb(3 32 88 / 60%)
    ),
    url("../images/png/loginbg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-user-main .login-pd-main {
  padding: 30px 15px;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-bg-content {
  background-image: linear-gradient(
      45deg,
      rgb(3 32 88 / 60%),
      rgb(3 32 88 / 60%)
    ),
    url("../images/png/signupbg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-logo-details .logo-login {
  max-width: 212px;
  height: 150px;
}

.login-form-details .login-max-content {
  max-width: 350px;
  margin: auto;
  width: 100%;
}

.signup-form-details .signup-max-details {
  max-width: 405px;
  margin: auto;
  width: 100%;
}

.referral-grid-inner .referral-column-inner {
  padding: 0px 10px;
}

.referral-grid-inner {
  margin: 0px -10px;
}

.login-form-details .login-details-title {
  margin-bottom: 30px;
}

.login-details-title .login-title {
  font-size: 50px;
  font-family: "Teko-SemiBold";
  color: #032058;
  line-height: 60px;
}

.login-details-title .login-desc {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #08080a;
  line-height: 21px;
}

.pass_group_main {
  position: relative;
}

.pass_group_main .pass_position {
  margin-left: 0px;
}

.pass_group_main .custom_form_control {
  padding-right: 0px;
  width: 100%;
}

.pass_group_main .custom_form_control input {
  padding-right: 45px;
}

.pass_group_main .icon_end_icon,
.pass_group_main .icon_end_icon:hover {
  /* position: absolute;
  right: 16px; */
  max-width: 100%;
  padding: 6px 8px;
  background-color: #fff;
  border-radius: 0px;
  color: #08080a;
  font-size: 14px;
  line-height: normal;
  min-height: 22px;
}

.mb-20px {
  margin-bottom: 10px;
}

.checkbox-main {
  text-align: center;
  display: flex;
  align-items: center;
}

.checkbox-main .custom-check span {
  border: 1px solid #032058;
  border-radius: 4px;
}

.checkbox-main .custom-check {
  margin-right: 8px;
  padding: 0px;
}

.checkbox-main span {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
}

.create-account-text .signup-redirect {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  text-decoration: none;
  color: #08080a;
}

.flex-check-login {
  /* margin-top: 10px; */
  /* margin-bottom: 40px; */
  margin-bottom: 10px;
}

.flex-forgot-password {
  /* margin-top: 10px; */
  /* margin-bottom: 40px; */
}

.back-login-forgot {
  margin-top: 10px;
  text-align: end;
}

.create-bottom-account {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  text-align: center;
}

.create-bottom-account .signup-redirect {
  color: #f66d32;
  margin-left: 5px;
}

.flex-reset-password {
  margin-top: 8px;
  /* margin-bottom: 40px; */
}

.flex-reset-password a {
  color: #e17025;
  font-size: 15px;
}

.account-link-details .already-account-title {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
  color: #08080a;
}

.account-link-details .already-account-title .redirect-account-link {
  color: #0371f1;
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  margin-left: 5px;
}

.account-link-details {
  margin-top: 20px;
}

.signin-btn-main {
  margin-top: 20px;
}

.login-grid-inner .login-column {
  padding: 0px 7.5px;
}

.login-grid-inner {
  margin: 0px -7.5px;
}

.login-pd-main {
  padding: 30px 15px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* login end */

.burgur-icon-main {
  margin-left: 10px;
  display: none;
}

.burgur-icon-main .outline-btn-list {
  min-width: inherit !important;
  min-height: 40px;
  padding: 5px 15px;
}

.social-icon-img {
  height: 25px;
  width: 25px;
}

.burgur-icon-main .outline-btn-main .outline-btn-list .store-img-home {
  margin-right: 0px;
}

.overlay-section {
  display: none;
}

.input-mb-20px {
  margin-bottom: 20px;
}

.signup-scroll-form {
  max-height: calc(100vh - 379px);
  overflow-y: auto;
  padding-right: 26px;
}

.mr-hgt-scroll {
  margin-right: -24px;
}

/* .footer-main-content {
    padding-bottom: 37px;
} */

.signup-scroll-form::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.signup-scroll-form::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.signup-scroll-form::-webkit-scrollbar-thumb {
  /* background: #88888845; */
  background: red;
}

/* Handle on hover */
.signup-scroll-form::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.drop-main-comman .dropdown-content {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  color: #08080a;
  font-size: 14px;
  line-height: normal;
  border-radius: 0px;
  height: 36px;
}

.drop-main-comman .dropdown-content .MuiSelect-select {
  padding: 0;
}

.flex-btn-innner-outline {
  text-align: start;
}

.step-home-list .step-home-register {
  padding: 0px 0px 20px 0px;
  display: flex;
  align-items: center;
}

.step-home-list .step-listitem-main {
  padding: 0px;
  position: relative;
}

.step-home-list .step-listitem-main::after {
  background-image: url("../images/png/orange-step-right.png");
  content: "";
  height: 32px;
  width: 32px;
  position: absolute;
  right: -1px;
}

.step-home-list .step-listitem-main:last-child:after {
  display: none;
}

.step-home-list .step-listitem-main .main-step-list-box {
  border: 1px solid #f5aa16;
  padding: 10px;
  border-radius: 12px;
  min-width: 180px;
  text-align: center;
}

.step-home-list .step-listitem-main .main-step-list-box .title-step-home-list {
  color: #fff;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
  line-height: 21px;
}

.step-home-list
  .step-listitem-main
  .main-step-list-box
  .title-step-home-coming {
  font-size: 15px;
  font-family: "Poppins-Regular";
  color: #fff;
  line-height: 21px;
}

.bg-virtual-icon-main {
  /* border: 2px solid #032058; */
  height: 75px;
  width: 75px;
  margin: 0px 10px 0px 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: #032058;
  justify-content: center;
  float: left;
}

.bg-virtual-icon-main .icon-joy-main {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

/* user dashboard  start*/

.header-sec {
  background-color: #032058;
  padding: 15px 0px;
}

.header-col {
  display: flex;
}

.header-nav-main .header-nav-ul {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.header-nav-main .header-nav-li {
  margin: 0px;
  padding: 0px;
  width: max-content;
}

.header-nav-main .header-nav-link {
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Regular";
  text-decoration: none;
  margin-right: 34px;
}

.header-nav-main .header-nav-li:last-child .header-nav-link {
  margin-right: 0px;
}

.header-btn-main .header-btn:last-child {
  margin-right: 0px;
}

.header-btn-main .header-btn {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-family: "Urbanist-Regular";
  margin-right: 15px;
  text-transform: capitalize;
  height: 37px;
  border-radius: 7px;
}

.header-btn-main .header-switch-btn {
  border: 1.5px solid #65e4a1;
  min-width: 108px;
}

.header-btn-main .header-signout-btn {
  border: 1.5px solid #2460ff;
  min-width: 111px;
  box-shadow: 0px 0px rgba(36, 96, 255, 0.4);
}

.header-btn-main {
  display: flex;
  justify-content: end;
}

.header-logo {
  object-fit: contain;
  height: 60px;
  min-width: initial;
}

.display-row {
  align-items: center;
}

.main-userdashboard-content {
  padding: 30px 0px;
}

.refferal-program-component {
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  padding: 35.5px 40.86px 35.5px 43.5px;
}

.refferal-title-details .refferal-title-content {
  font-size: 22px;
  line-height: 26px;
  color: #000;
  font-family: "Poppins-SemiBold";
}

.refferal-title-details .refferal-title-content span {
  font-family: "Poppins-Regular";
}

.refferal-rarn-details .earn-title-refferal {
  color: #000;
  font-size: 18px;
  line-height: 22px;
  font-family: "Poppins-Medium";
}

.flex-title-earn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.table-refferal-main .refferal-main-paper-table {
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 1096px);
}

.role-permission-paper-table {
  padding: 15px;
}

.table-refferal-main .refferal-main-table {
  min-width: 824px;
}

.input-link-main .history-link-control input {
  padding: 11px 20px 12px;
  background: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  min-width: 489px;
  width: 100%;
  border: transparent;
  margin-right: 15px;
}

.blue-border-btn-main .blue-outline-btn {
  border: 1px solid #032058;
  border-radius: 12px;
  padding: 5px 30px;
  min-height: 45px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 25px;
  color: #032058;
  text-transform: capitalize;
  box-shadow: none;
}

.link-main-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.btn-history-list {
  margin-right: 15px;
}

.main-pending .btn-pending-list {
  border: 1px solid #e37723;
  padding: 5px 20px;
  color: #000000;
  text-transform: capitalize;
  font-family: "Poppins-Regular";
}

.refferal-main-table .refferal-row-table .refferal-cell-table:nth-child(1),
.refferal-main-table
  .refferal-datarow-table
  .refferal-datacell-table:nth-child(1) {
  padding-left: 0px;
  width: 35%;
}

.refferal-main-table .refferal-row-table .refferal-cell-table:nth-child(2),
.refferal-main-table
  .refferal-datarow-table
  .refferal-datacell-table:nth-child(2) {
  width: 8%;
  text-align: end;
}

.refferal-main-table .refferal-row-table .refferal-cell-table:nth-child(3),
.refferal-main-table
  .refferal-datarow-table
  .refferal-datacell-table:nth-child(3) {
  width: 8%;
  text-align: end;
}

.refferal-main-table .refferal-row-table .refferal-cell-table:nth-child(4),
.refferal-main-table
  .refferal-datarow-table
  .refferal-datacell-table:nth-child(4) {
  width: 8%;
  text-align: end;
}

.refferal-main-table .refferal-row-table .refferal-cell-table:nth-child(5),
.refferal-main-table
  .refferal-datarow-table
  .refferal-datacell-table:nth-child(5) {
  width: 8%;
  text-align: end;
}

.refferal-main-table .refferal-row-table .refferal-cell-table:last-child,
.refferal-main-table
  .refferal-datarow-table
  .refferal-datacell-table:last-child {
  padding-right: 0px;
  width: 8%;
  text-align: end;
}

.refferal-main-table .refferal-datarow-table .refferal-datacell-table {
  font-size: 12px;
  font-family: "Poppins-Regular";
  padding: 0;
}

.refferal-main-table .refferal-row-table .refferal-cell-table {
  font-size: 14px;
  line-height: 20px;
  color: #032058;
  font-family: "Poppins-SemiBold";
  padding: 0px;
}

.refferal-body-table .refferal-datarow-table {
  font-family: "Poppins-Regular";
  color: #101010;
}

.refferal-namelist .table-refferal-name {
  font-size: 14px;
  font-family: "Poppins-Regular";
  line-height: 20px;
  color: #111;
}

.refferal-namelist .name-list-time {
  color: #758698;
}

.refferal-menu-main .basic-dot-main {
  background: #e7e7e7;
  padding: 15px 10px;
  min-width: inherit;
}

.user-dashboard-row {
  display: flex;
  flex-wrap: wrap;
}

.userdash-col1 {
  max-width: 200px;
  width: 100%;
  flex: 0 0 200px;
}

.userdash-col2 {
  flex: 1;
}

.sidebar-user-main .list-user-sidebar {
  padding: 0px;
}

.sidebar-user-main .list-user-sidebar .listitem-user-sidebar {
  padding-left: 0px;
}

.sidebar-user-main
  .list-user-sidebar
  .listitem-user-sidebar
  .user-sidebar-link {
  padding: 0;
  text-decoration: none;
  font-size: 18px;
  line-height: 22px;
  color: #2b2b2b;
  font-family: "Poppins-Regular";
  margin: 0 0 20px;
}

.sidebar-user-main {
  padding: 44px 0 0;
}

.sidebar-user-main
  .list-user-sidebar
  .listitem-user-sidebar.active
  .user-sidebar-link {
  color: #032058;
}

.MuiMenu-paper {
  box-shadow: 0 0 10px rgba(182, 175, 175, 0.15) !important;
}

.link-user-logo {
  display: flex;
}

.profile-stepper-amin {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.profile-stepper-amin::after {
  content: "";
  position: absolute;
  background: #85838373;
  width: calc(100% - 60px);
  height: 1px;
  top: 22px;
  z-index: -1;
  left: 11px;
}

.profile-step-details .steper-box-profile {
  height: 45px;
  width: 45px;
  background-color: #858383;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: auto;
}

.profile-step-details.active .steper-box-profile {
  background: linear-gradient(45deg, #05235d, #305eba);
}

.profile-step-details.active .profile-steplabel-main {
  color: #032058;
}

.profile-step-details .profile-steplabel-main {
  max-width: 152px;
  font-family: "Poppins-Medium";
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

.profile-tab-box .profile-stepper-amin {
  margin-bottom: 30px;
}

.stepper-signup-grid {
  margin: 0px -10px !important;
}

.stepper-signup-grid .stepper-signup-column {
  padding: 0px 10px;
}

.signup-list-content .stepper-signup-grid {
  margin: 0px -10px;
}

.step-one-details-content .step-one-next-prev-btn {
  justify-content: end;
}

.step-one-next-prev-btn {
  padding-top: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .primary-btn-main-user {
    margin-left: auto;
} */

.card-dash-main {
  background: linear-gradient(45deg, #05235d, #305eba);
}

.card-dash-main .card-dash-body {
  padding: 20px 15px;
}

.logo-bg-card {
  background: rgb(255 255 255 / 20%);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.small-logo {
  height: 30px;
  width: 30px;
}

.flex-details-card {
  display: flex;
  align-items: center;
}

.details-card-main .card-dash-detail {
  color: #fff;
  font-size: 16px;
  font-family: "Poppins-Medium";
}

.details-card-main .card-dash-title {
  color: #fff;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 19px;
}

.flex-price-lock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 12px;
  margin-top: 30px;
}

.flex-price-lock .locked-card-main,
.flex-price-lock .available-card-main {
  margin-right: 10px;
}

.wallet-btn-main .outline-btn-list {
  min-width: initial;
  min-height: initial;
  padding: 3px 10px;
  font-size: 11px;
  border-radius: 9px;
}

.wallet-btn-main .outline-btn-list img {
  display: none;
}

.card-dashbord-main .card-dash-grid {
  margin: 0px -8px;
}

.card-dash-grid .card-dash-column {
  padding: 0px 8px;
}

.card-dashbord-main {
  padding-bottom: 40px;
}

.form-gendergroup-control svg path,
.height-radio-main .form-heightgroup-control svg {
  fill: #032058;
}

.fitness-list-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;
}

.fitness-goal-flex .fitness-goal-box {
  display: flex;
  align-items: center;
  border: 2px solid #e4e4e4;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 4%);
  cursor: pointer;
}

.fitness-goal-flex {
  width: calc(100% / 4);
  padding: 0px 10px 10px 10px;
}

.fitness-goal-img-box {
  display: flex;
  margin-right: 10px;
}

img.fitness-img {
  height: 42px;
  width: 42px;
}

.fitness-goal-box .fitness-title-main {
  font-size: 15px;
  font-family: "Poppins-Medium";
  line-height: 20px;
}

.fitness-goal-flex .fitness-goal-box.active {
  border-color: #e57c22;
}

.file-upload-btn-main-user .file-upload-btn-user,
.file-upload-btn-main-user .file-upload-btn-user:hover,
.file-upload-btn-main-user .file-upload-btn-user.active {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 6px 15px;
  background-color: #fff;
  border-radius: 0;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
  min-height: 22px;
  box-shadow: none;
  justify-content: start;
}

.file-upload-btn-main-user {
  position: relative;
}

.button-text-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.button-text-main .title-upload-main {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}

.upload-img {
  height: 32px;
  width: 32px;
  object-fit: contain;
  margin-right: 10px;
}

.datepicker-wrap .date-text-list {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 0px;
  color: #08080a;
  font-size: 14px;
  line-height: normal;
}

.datepicker-wrap .date-text-list input {
  padding: 0px;
  font-family: "Poppins-Regular", sans-serif;
}

.step-four-account-details .title-health-challenges,
.step-third-account-details .title-health-challenges {
  font-size: 20px;
  font-family: "Poppins-Medium";
  color: #000000;
  margin-bottom: 20px;
}

/* input dropdown */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-box .form-control .dropdown-select {
  background: #ffffff;
  border: 1px solid #a6ccff;
  background-color: transparent;
  border-radius: 0px;
  color: #08080a;
  font-size: 14px;
  line-height: normal;
}

.dropdown-select .MuiSelect-select {
  color: #08080a;
  font-family: "Poppins-Regular";
  display: flex;
  padding: 6px 12px !important;
  font-size: 14px;
  line-height: normal;
  height: 0 !important;
  min-height: 22px !important;
  background-color: #fff;
}

.form-group .form-control fieldset {
  border: 0px;
  border-width: 0px;
  border-color: transparent;
}

.MuiMenu-list .menuitem {
  font-size: 14px;
  color: #08080a;
  font-family: "Poppins-Regular";
}

/* otp start */
.flex-top-input {
  display: flex;
  align-items: center;
  margin: 0px -10px;
}

.flex-top-input .otp-controller {
  padding: 0px 10px;
}

.form-group .otp-controller input {
  text-align: center;
  font-size: 16px;
}

/* otp end */

.mb-10px {
  margin-bottom: 10px;
}

.min-height-255px {
  min-height: 307.5px;
  margin-bottom: 10px;
}

.min-height-625px {
  min-height: 625px;
}

/* new roadmap list */
.roadmap-details-content {
  max-width: 293px;
  margin: -18px 44px 0px 0px;
}

.roadmap-feature-benifitis-title .roadmap-title-main-list {
  color: #f5aa16;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Poppins-Medium";
}

.roadmap-feature-benifitis-title .list-feature-main {
  padding: 0px;
  list-style: arabic-indic;
}

.roadmap-feature-benifitis-title .list-feature-main .feature-listitem-main {
  padding: 0px 0px 10px 0px;
  font-family: "Poppins-Regular";
  color: #ffff;
  font-size: 12px;
  line-height: 18px;
  display: flex;
}

.roadmap-feature-benifitis-title
  .list-feature-main
  .feature-listitem-main
  .mr-span-road {
  margin-right: 10px;
}

.list-roadmap-details
  .listitem-roadmap-details:nth-child(even)
  .roadmap-details-content {
  margin: -18px auto 0px 148px;
  max-width: 313px;
  padding-left: 31px;
}

.roadmap-details-content .phase-title-main {
  color: #fff;
  font-size: 14px;
  font-family: "Poppins-Regular";
  margin: 10px 0px;
}

.list-roadmap-details .listitem-roadmap-details:after {
  content: "";
  background: #fff;
  height: 100%;
  width: 1px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}

.list-roadmap-details .listitem-roadmap-details:last-child:after {
  display: none;
}

.display-none-step {
  display: none;
}

.step-block {
  display: block;
}

/* custom scroller */

.roadmap-list-table {
  padding: 0px !important;
}

.userDetail-btn-lowercase {
  text-transform: none !important;
}

.profile-tab-list-main {
  margin-top: 40px;
}

.user-btn-end {
  justify-content: end;
}

.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  padding: 30px;
  max-width: 400px;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.delete-modal-inner-main .delete-modal-title {
  color: #111827;
  font-size: 21px;
  line-height: 28px;
  font-family: "Poppins-Medium";
  text-align: center;
  /* margin: 0px 0px 15px; */
}

.delete-modal-inner-main .delete-modal-para {
  color: #6b7280;
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
  font-size: 12px !important;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.update-status-modal-btn-flex {
  display: flex;
  justify-content: right;
  gap: 5px;
  margin-top: 5px;
  padding: 5px;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background-color: #e4004d;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

.modal-btn-ok .comman-primary-btn {
  min-width: 121px;
}

.delete-modal-inner-main:focus-visible {
  outline: none;
}

/* end delete user modal css */

.loader-bg-main {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
  background: #32323291;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader-inner-border {
  border-color: #e17025 #e17025 #f5aa16 #f5aa16;
  border-style: solid;
  border-width: 3px;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  -webkit-animation: animNameloader 1s linear infinite;
  animation: animNameloader 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-bg-main .logo-small-loader {
  width: 50px;
  height: 50px;
  object-fit: contain;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@keyframes animNameloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.loader-inner-bg-main {
  position: relative;
}

.auth-social-icon-main .auth-footer-social-icon-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px 0px;
}

.auth-social-icon-main .auth-footer-social-icon-ul .auth-footer-social-icon-li {
  height: 45px;
  width: 100%;
  padding: 0px;
  border: 1px solid #032058;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 12px;
  min-height: 45px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 25px;
}

.auth-social-icon-main .auth-footer-list {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0px;
}

.auth-social-icon-main .auth-footer-list .signin-auth-text {
  font-size: 14px;
  color: #08080a;
  font-family: "Poppins-Medium";
  line-height: 20px;
}

.auth-social-icon-main
  .auth-footer-social-icon-ul
  .auth-footer-social-icon-li
  .auth-social-redirect-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  justify-content: center;
}

.auth-social-icon-main
  .auth-footer-social-icon-ul
  .auth-footer-social-icon-li
  .auth-social-icon-img {
  height: 30px;
  object-fit: contain;
  width: 26px;
}

.auth-social-icon-main
  .auth-footer-social-icon-ul
  .auth-footer-social-icon-li
  .auth-social-icon-img {
  height: 27px;
  object-fit: contain;
  width: 19px;
}

.pass-num-main .num-position {
  position: absolute;
  left: 11px;
}

.pass-num-main .num-position p {
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}

.num-main-input {
  position: relative;
}

.num-main-input .custom-control {
  padding-left: 0px;
}

.num-main-input .form-group .form-control input {
  padding-left: 52px;
}

.flex-center-radio-hgt {
  display: flex;
  align-items: center;
  /* margin-bottom: 16px; */
  justify-content: space-between;
}

.form-heightgroup-control .MuiRadio-colorPrimary {
  padding: 0px 5px 0px 0px;
}

.flex-center-radio-hgt .form-label {
  margin-right: 14px;
  margin-bottom: 0px;
}

.height-radio-main .form-heightgroup-control svg {
  height: 18px;
  width: 18px;
}

.height-radio-main .form-heightgroup-control {
  margin-right: 18px;
}

.auth-social-icon-main .auth-footer-list .signin-auth-text {
  font-size: 14px;
  color: #08080a;
  font-family: "Poppins-Medium";
  line-height: 20px;
  margin-left: 11px;
}

.bms-btn-content {
  margin-top: 34px;
}

.form-flex-input {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}

.form-flex-input .dropdown-box {
  width: 100%;
}

.text-height-drop .text-drop-list {
  border: 1px solid #a6ccff;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px !important;
  font-size: 14px;
  line-height: normal;
  height: 100% !important;
  min-height: 22px !important;
  background-color: #fff;
}

/* .height-stepper-main .dropdown-box .form-control .dropdown-select {
  padding: 6px 12px !important;
  font-size: 14px;
  line-height: normal;
  height: auto !important;
  min-height: 22px !important;
  background-color: #fff;
  border-radius: 0;
} */

.custom-form-details .username-text-orange {
  color: #e27324;
}

.text-weight-main .text-weight-list {
  border: none;
  background-color: transparent;
  border-radius: 0px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
  min-height: 45px;
  display: flex;
  align-items: center;
  padding: 10.5px 10px;
  border-left: 1px solid #a6ccff;
}

.position-main-weight {
  position: relative;
}

.position-main-weight .text-weight-main {
  position: absolute;
  right: 0;
  top: 0;
}

.position-main-weight .form-control input {
  padding-right: 55px;
}

.step-height-text .flex-center-radio-hgt .form-label {
  margin-top: 0px;
}

.height-radio-main .form-heightgroup-control .MuiFormControlLabel-label {
  font-size: 14px;
  color: #08080a;
  font-family: "Poppins-Medium";
  line-height: 20px;
}

.congratulations-modal-last-step .delete-modal-title {
  font-size: 15px;
  line-height: 25px;
}

.cong-img-content .certificate-img {
  width: 75px;
  height: 75px;
}

.cong-img-content {
  text-align: center;
  margin-bottom: 10px;
}

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.pagination-main .MuiPaginationItem-root {
  height: 26px;
  min-width: 26px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  background-color: #d5d5d5;
  font-family: "Poppins-Regular";
  margin: 0 4px;
}

.pagination-main
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: #a6ccff;
  color: #05225c;
  font-weight: bold;
}

.flex-btn .welcome-person-name {
  color: #ffff;
  width: 100%;
  min-width: 250px;
  margin-right: 20px;
  text-align: end;
}

.country-dropdown {
  width: 150px !important;
}

.report-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.report-highlight-list .list-report-hgtlight {
  padding: 0px;
}

.report-highlight-list .list-report-hgtlight .listitem-report-hightlight {
  padding: 0px;
}

.report-highlight-list
  .list-report-hgtlight
  .listitem-report-hightlight
  .text-report-content {
  color: #000000;
}

.report-highlight-list
  .list-report-hgtlight
  .listitem-report-hightlight
  .box-report-main {
  height: 15px;
  width: 15px;
  margin-right: 9px;
}

.orange-bg {
  background: #fd4525;
}

.light-orange-bg {
  background: #f2a636;
}

.light-green-bg {
  background: #d5e544;
}

.green-bg {
  background: #7dd843;
}

.refferal-title-details.flex-bmi-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.refferal-score {
  background: #d3cbcb;
  min-width: 100px;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  color: #000;
}

/* .custom-share-width {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.custom-share-width .st-btn.st-remove-label {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    min-height: 45px;
}

.custom-share-width .st-btn > img {
    height: 22px !important;
    width: 22px !important;
    top: 0 !important;
} */

.referral-drop-down-main {
  display: flex;
  align-items: center;
}

.referral-drop-down-main .country-title {
  margin-right: 10px;
}

.referral-drop-down-main .dropdown-select .MuiSelect-select {
  border-radius: 10px !important;
  padding: 6px 10px !important;
}

.referral-drop-down-main .dropdown-box .form-control .dropdown-select {
  border-radius: 9px;
}

.refresh-btn-main .refresh-btn {
  background: linear-gradient(45deg, #e27530, #f3a332);
  padding: 7px 7px;
  max-width: 50px;
  min-width: 50px;
  border-radius: 9px;
}

.refresh-btn-main .refresh-btn img {
  height: 21px;
}

.refresh-btn-main {
  margin-left: 10px;
}

.remove-underline {
  text-decoration: none;
}

.st-label {
  display: none !important;
}

.share-link-referral .st-inline-share-buttons .st-btn {
  min-height: 15px !important;
}

.share-link-referral .st-inline-share-buttons.st-btn[data-network="whatsapp"] {
  min-width: 50px !important;
}

.share-link-referral .st-inline-share-buttons .st-btn[data-network="facebook"] {
  min-width: 50px !important;
}

.share-link-referral .st-inline-share-buttons .st-btn {
  min-width: 50px !important;
  display: block !important;
}

.share-link-referral {
  justify-content: end;
}

.share-link-referral .st-inline-share-buttons {
  display: flex !important;
  align-items: center;
}

.share-btn-list {
  display: flex;
  justify-content: end;
}

.close-share-btn .refresh-btn {
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;
  border-top-right-radius: 10px !important;
}

.close-share-btn .refresh-btn img {
  height: 10px !important;
  width: 10px !important;
}

.kyc-doc-name {
  font-size: 14px !important;
  color: #08080a !important;
  font-family: "Poppins-Medium" !important;
  line-height: 20px !important;
}

.resend-otp {
  cursor: pointer;
  color: #e17025;
  font-size: 15px;
}

.user-edit-show-document {
  display: flex;
}

.user-edit-show-document img {
  width: 100px;
  height: 100px;
}

.green-user-text {
  color: #16a716 !important;
}

.user-register-icon .icon-user {
  margin-right: 8px;
  height: 17px;
  width: 17px;
  object-fit: contain;
  display: flex;
  margin-top: -2px;
}

.user-register-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* admin & user loading button start */
.comman-loader-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.comman-loader-button2 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.comman-loader-button-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

/* admin & user loading button end */

.submenu-list-details .admin-submenu-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.submenu-list-details {
  flex-direction: column;
}

.admin-text-details-sub {
  display: flex;
  align-items: center;
  gap: 10px;
}

.admin-sidebar-list-main .sub-menu {
  align-items: left !important;
}

/* .submenu-main {
  width: 100%;
  margin-top: 10px;
} */

.admin-sidebar-list-main .admin-sidebar-listitem .admin-sidebar-submenulist {
  margin: 10px 0px;
}

.height-radio-main label {
  margin-right: 0 !important;
}

.height-radio-main .form-heightgroup-control-main {
  display: flex;
  gap: 20px;
  justify-content: end;
}

.userDetail-btn-lowercase.view-link,
.userDetail-btn-lowercase.view-link:hover {
  justify-content: center;
  padding: 4px 8px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background-color: #0371f1;
}

.warehouse-dropdown-box {
  display: flex;
  align-items: end;
  gap: 16px;
  margin-bottom: 16px;
}
