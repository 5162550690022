.admin-main {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background-color: var(--background);
}

.admin-main .auth-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    gap: 30px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-image: url("../assets/images/jpg/new-bg-login.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}


.admin-main .auth-main .auth-left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 50px 15px;
}

.admin-main .auth-main .auth-left .company-logo {
    width: 100%;
    max-width: 300px;
    height: auto;
}

.admin-main .auth-main .auth-right {
    width: 100%;
    overflow: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 15px;
}


.admin-main .auth-main .auth-common-box {
    padding: 20px;
    border-radius: var(--radius6);
    box-shadow: var(--shadow-sm);
    background-color: #fff;
    /* background-image: url("../assets/images/jpg/login-bg-shape.jpg");
    background-size: cover; */
    width: 100%;
    max-width: 390px;
}

.admin-main .auth-main .auth-header {
    margin-bottom: 30px;
}

.admin-main .auth-main .auth-header .auth-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 5px;
}

.admin-main .auth-main .auth-header .auth-subtitle {
    color: #989898;
    font-size: 14px;
    line-height: 18px;
}

.admin-main .auth-link {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: var(--primary);
}

.admin-main .auth-link.forgot-password {
    justify-content: flex-end;
}

.admin-main .auth-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.admin-main .form-group .form-input.otp-input-box {
    justify-content: space-between;
    gap: 15px;
}

.admin-main .form-group .form-input.otp-input-box input {
    width: 100%;
    border: 2px solid var(--secondary);
    text-align: center;
    font-family: inherit;
}

.admin-main .form-group .form-input.otp-input-box input:focus,
.admin-main .form-group .form-input.otp-input-box input:focus-visible {
    border: 2px solid var(--primary);
    outline: none;
}


/* Start Second Design */
.auth-main.auth-main-box {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    gap: 30px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-image: url("../assets/images/png/new-bg-login-11.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.admin-main .auth-main.auth-main-box .auth-common-box {
    /* background: rgb(2, 0, 36); */
    background: #18181a;
}

.admin-main .auth-main.auth-main-box .auth-header .auth-title,
.admin-main .auth-main.auth-main-box .input-box .form-lable,
.admin-main .auth-main.auth-main-box .admin-forgot-para a{
    color: #fff;
}

.admin-main .auth-main.auth-main-box .form-group .form-control input {
    color: #000;
}
/* End Second Design */



/* Start Third Design */
.admin-main .auth-main.auth-main-third{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    gap: 30px;
    width: 100%;
    position: relative;
    z-index: 1;
    background-image: url("../assets/images/jpg/new-bg-login-2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.admin-main .auth-main.auth-main-third .auth-common-box{
    max-width: 680px;
    background-image: url("../assets/images/png/login-box-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background: linear-gradient(to left, #18181a 50%, #ffffff 50%); */
}
/* End Third Design */
.action-btn-icon{
    gap: 8px;
    justify-content: center;
}
.action-btn-icon button{
    padding: 4px;
    min-width: auto;
    background: transparent;
}
.action-btn-icon svg {
    width: 18px;
    height: 18px;
    fill: #05225c;
}

.action-btn-icon button:hover svg{
    fill: #fff;
    
}