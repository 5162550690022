* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/poppins-bold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Urbanist-Medium";
  src: url("../fonts/Urbanist/Urbanist-Medium.ttf");
}

@font-face {
  font-family: "Urbanist-Light";
  src: url("../fonts/Urbanist/Urbanist-Light.ttf");
}

@font-face {
  font-family: "Urbanist-Regular";
  src: url("../fonts/Urbanist/Urbanist-Regular.ttf");
}

@font-face {
  font-family: "Urbanist-Bold";
  src: url("../fonts/Urbanist/Urbanist-Bold.ttf");
}

.cursor-pointer {
  cursor: pointer !important;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.error-message {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  color: #d32f2f;
}

.page-bg {
  background-image: linear-gradient(140deg, #05225c, #2b57ae 150%);
}

.flex-all {
  display: flex;
  justify-content: space-between;
}

.gym-list-table-cell .gym-list-amenities {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

.form-group .form-control input {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 0px;
  color: #08080a;
  font-size: 14px;
  line-height: normal;
  min-height: 22px;
}

.form-group .text-custom-control {
  width: 100%;
  min-height: 138px;
  background: transparent;
  border-radius: 14px;
  font-family: "Poppins-Regular", Arial, sans-serif;
  border: 1px solid #a6ccff;
}

.form-group .form-control input::placeholder {
  color: #6b7280;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular", Arial, sans-serif;
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #a6ccff;
  border-width: 0px;
}

fieldset {
  border-color: transparent !important;
  top: 0px !important;
}

.btn-main .btn,
.btn-main .btn:hover {
  width: 100%;
  max-width: 380px;
  background: transparent linear-gradient(270deg, #f5aa16 0%, #e17025 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 5px 30px;
  min-height: 45px;
  font-family: "Poppins-Regular", Arial, sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  text-transform: capitalize;
  box-shadow: none;
}

.add-user-input .nft-form-description-textfield {
  border: 1px solid #a6ccff;
  border-radius: 0px;
}

.add-edit-goal-form .edit-profile-flex {
  margin-top: 1px;
}

.form-group .error-description-nftform {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
}

.file-upload-btn-main .error-nft-image-nftform {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  position: absolute;
  bottom: -10px;
  width: max-content;
}

.border-btn-main .border-btn {
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: transparent;
  padding: 6px 14px 5px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  font-family: "Poppins-Regular", Arial, sans-serif;
  text-transform: capitalize;
}

.border-btn-main .border-btn:hover {
  background-color: transparent;
  padding: 6px 14px 5px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  min-width: 100px;
  border: 1px solid #000000;
  border-radius: 4px;
}

.common-modal .common-modal-inner {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  max-height: calc(100% - 100px);
  /* max-height: 650px; */
  overflow: auto;
  max-width: 650px;
  padding: 20px;
}

.report-modal .report-modal-inner {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  max-height: calc(100% - 100px);
  /* max-height: 650px; */
  overflow: auto;
  max-width: 650px;
  padding: 20px;
  border: 0px;
}

.common-modal .user-setup-modal-inner {
  min-width: 800px;
}

.report-modal .user-setup-modal-inner {
  min-width: 400px;
  max-width: 400px;
}

.common-modal .modal-close-icon {
  cursor: pointer;
  height: 20px;
  object-fit: contain;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
}

.report-modal .modal-close-icon {
  cursor: pointer;
  height: 20px;
  object-fit: contain;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
}

.report-modal-inner .modal-header {
  padding-bottom: 5px;
}

.report-modal-inner .report-modal-img-box {
  text-align: center;
  border-radius: 6px;
}

.report-modal-inner .report-modal-reportedBy-box {
  margin: 5px 0px;
  display: flex;
}

.report-modal-inner .report-user-modal-reportedBy-box {
  margin: 5px 0px;
  /* display: flex; */
}

.report-user-modal-innerbox {
  display: flex;
}

.report-user-modal-user-detail-container {
  display: flex;
}

.report-modal-tag-name {
  width: 105px;
  margin: 5px 0px 0px 0px;
  /* padding-top: 3px; */
  font-size: 16px;
}

.colon-special {
  margin-top: 5px !important;
}

.report-modal-reportedBy-box .reported-username {
  font-size: 14px;
  margin: 7px;
  padding-top: 0px;
}

.report-modal-inner .report-modal-report-box {
  height: auto;
}

/* width */
.common-modal-inner::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.common-modal-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

/* Handle */
.common-modal-inner::-webkit-scrollbar-thumb {
  background: var(--main-primary);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

/* Handle on hover */
.common-modal-inner::-webkit-scrollbar-thumb:hover {
  background: var(--main-primary);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.report-modal .report-modal-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.report-modal-content .report-user-image {
  width: 360px;
  border-radius: 6px;
}

.report-modal-report-para {
  font-size: 12px;
  line-height: 22px;
  color: #6b7280;
  font-family: "Poppins-Regular", Arial, sans-serif;
  margin: 0px 0px 5px 0px;
}

/* SINGLE GROUP CSS  */
.singlegroup-title {
  color: #111827 !important;
  font-size: 26px !important;
  line-height: 39px !important;
  font-family: "Poppins-SemiBold", Arial, sans-serif !important;
  margin-bottom: 13px !important;
  margin-top: 13px !important;
  text-transform: capitalize;
}

.singlegroup-data-main {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.06),
    0 3px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 8px 0 rgba(0, 0, 0, 0.04);
}

.single-group-leftbox {
  display: flex;
}

.leftbox-image-box {
  max-width: 40%;
  width: 100%;
  flex: 0 0 40%;
  padding: 16px;
}

.leftbox-trainer-detail-box {
  max-width: 55%;
  width: 100%;
  flex: 0 0 55%;
  /* padding-top: 20px; */
}

.signgle-data-main {
  flex: 1;
}

.singlegroup-leftbox-ul-list {
  padding: 10px 20px;
  list-style: none;
}

.trainerdetail-leftbox-ul-list {
  list-style: none;
}

.trainerdetail-leftbox-ul-list>li {
  display: flex;
  gap: 10px;
  padding: 2px 0px;
}

.singlegroup-leftbox-ul-list>li {
  display: flex;
  gap: 15px;
  padding: 5px 0px;
}

.singlegroup-leftbox-img {
  width: 100%;
  height: 272px;
  object-fit: cover;
  border-radius: 10px;
}

.single-group-table-box {
  background-color: #ffffff;
  min-height: auto;
  padding: 28px 24px 24px 24px;
}

.singlegroup-tag-name {
  margin: 3px 15px;
  font-size: 14px;
  width: 150px;
}

.trainer-detail-fullname-tag-name {
  margin: 3px 15px;
  font-size: 14px;
  width: 131px;
}

.singlegroup-tag-name-detail {
  margin: 3px 15px;
  font-size: 14px;
  /* width: 150px; */
  max-width: 220px;
  word-wrap: break-word;
}

.trainer-detail-fullname-value {
  margin: 3px 15px;
  font-size: 14px;
  width: 272px;
}

.trainer-detail-fullname-value-rightside {
  width: 225px;
  word-break: break-all;
}

.singleNFt-description {
  width: 53%;
  height: auto;
}

.singlegroup-tag-name-colon {
  width: 5px;
  margin-top: 3px;
}

/* .single-group-setup-table-main{

} */
.admin-dash-box .single-group-setup-table-main .table {
  min-width: 950px;
}

.single-group-setup-table-main .table .table-th:nth-child(1),
.single-group-setup-table-main .table .table-td:nth-child(1) {
  width: 20%;
}

.single-group-setup-table-main .table .table-th:nth-child(2),
.single-group-setup-table-main .table .table-td:nth-child(2) {
  width: 20%;
}

.single-group-setup-table-main .table .table-th:nth-child(3),
.single-group-setup-table-main .table .table-td:nth-child(3) {
  width: 20%;
}

/* SINGLE GROUP CSS  */
.table-icon-btn .table-analytic-icons {
  height: 30px;
  width: 35px;
  object-fit: contain;
}

.input-box {
  width: 100%;
}

/* .input-box .forget-email {
  margin-top: 36px !important;
} */
.comman-modal-header {
  width: 100%;
  padding-bottom: 10px;
  /* padding: 15px; */
  /* border-bottom: 1px solid #efefef; */
  position: relative;
  font-size: 18px;
  font-family: "Poppins-SemiBold", Arial, sans-serif;
  color: #18181a;
  font-weight: bold;
  text-shadow: 1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff,
    1px 1px 5px #555;
  text-align: center;
}

.comman-modal-header::after {
  position: absolute;
  content: "";
  display: block;
  height: 3px;
  width: 50px;
  background: transparent linear-gradient(270deg, #f5aa16 0%, #e17025 100%) 0% 0% no-repeat padding-box;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-bottom: 24px;
}

/* .comman-modal-body {
  width: 100%;
  padding: 20px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
} */
.common-modal .add-edit-goal-inner {
  min-width: 500px;
  max-width: 500px;
}

.comman-modal-footer {
  /* width: 100%;
  padding: 15px;
  text-align: right; */
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
}

.admin-menu-box {
  width: 120px;
}

/* .comman-modal-footer button {
  margin-left: 15px;
} */

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

/* start admin login css */
.center-logo-login {
  text-align: center;
  margin-bottom: 20px;
}

.admin-login-main {
  margin: auto;
  max-width: 380px;
  width: 100%;
}

.admin-login-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-btn-main .login-btn {
  margin-top: 28px;
}

.checkbox-main .checkbox-lable {
  color: #6b7280;
  margin-left: 0px;
  margin-right: 0px;
}

.checkbox-lable .MuiCheckbox-root {
  padding: 0px;
  margin-right: 13px;
}

.checkbox-lable .MuiFormControlLabel-label {
  font-family: "Poppins-Regular", Arial, sans-serif;
  font-size: 14px;
  line-height: 21px;
}

.admin-login-inner .admin-wel-text {
  color: #111827;
  font-size: 24px;
  line-height: 25px;
  font-family: "Poppins-Bold", Arial, sans-serif;
  margin-bottom: 4px;
  text-align: center;
}

.admin-login-inner .admin-sign-para {
  margin-bottom: 32px;
  color: #6b7280;
  text-align: center;
}

.input-box .form-lable {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium", Arial, sans-serif;
  margin-bottom: 6px;
  margin-top: 0px;
  color: #000000;
  font-weight: 600;
}

.input-box-admin {
  margin-bottom: 20px;
}

.admin-forgot-para {
  color: #004dd1;
}

.input-box .MuiInputBase-root {
  width: 100%;
  font-size: 14px;
}

.input-box .css-1bn53lx {
  padding-right: 0;
}

.input-box .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 6px 12px;
}

.input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.checkbox-main .MuiSvgIcon-root {
  color: #004dd1;
  width: 20px;
  height: 20px;
}

/* column login start */
.login-main-row {
  display: flex;
  flex-wrap: wrap;
}

.log-col-6 {
  width: 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.login-left-image {
  background-image: linear-gradient(140deg, #000000, #004dd1 180%);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.center-logo-login .logo-admin {
  min-height: 140px;
}

.card-login-main {
  /* background: #ffffff; */
  padding: 30px 20px;
  border-radius: 10px;
  /* box-shadow: 0px -1px 7px #ffffff; */
}

/* column login end */

/* end admin login css */

/* start admin dashboard page structure css */

.dashboard-left-main {
  flex: 0 0 250px;
  max-width: 250px;
  width: 100%;
}

.dashboard-right-main {
  flex: 1;
  background-image: url("../images/jpg/main-bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.dashboard-containt-main {
  /* background-color: #ffffff; */
  min-height: 100vh;
  padding: 88px 16px 16px 16px;
}

.nftStake-containt-main {
  background-color: #ffffff;
  /* min-height: 100vh; */
  padding: 88px 24px 24px 24px;
}

.nftstake-table {
  padding: 0;
}

.dashboard-main {
  display: flex;
  width: 100%;
}

/* end admin dashboard page structure css */

/* start admin sidebar css */

.admin-sidebar-icons {
  /* margin-right: 8px; */
  display: flex;
}

.admin-sidebar-main {
  background: linear-gradient(157deg, #05225c, #2c59b1 553%);
  height: 100vh;
  left: 0;
  overflow: auto;
  /* padding: 0px 0px 16px 16px; */
  position: fixed;
  top: 0;
  width: 250px;
  z-index: 999;
  border-right: 1px solid #e5e7eb;
}

.admin-sidebar-logo {
  max-width: 130px;
  /* height: 80px; */
  width: 100%;
  object-fit: contain;
  padding: 4px 0;
  margin-top: 10px;
}

.admin-sidebar-logo-main .cancel-mobile-menu-icon {
  display: none;
}

.admin-sidebar-logo-main {
  text-align: center;
}

.admin-sidebar-list-main .admin-sidebar-list {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-link {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium", Arial, sans-serif;
  color: #ffffff;
  display: flex;
  /* gap: 10px; */
  padding: 10px 10px 10px;
  /* margin-bottom: 10px; */
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
  /* color: #000000;
  background-color: #ffffff; */
  color: #fff;
  background: transparent linear-gradient(270deg, #f5aa16 0%, #e17025 100%) 0% 0% no-repeat padding-box;
  /* background-color: #00235f; */
  border-top-left-radius: 48px;
  border-bottom-left-radius: 48px;
  gap: 10px;
}

/* 
.admin-sidebar-list-main .admin-sidebar-link.active svg path {
  fill: #000000;
} */

/* end admin sidebar css */

/* start admin header css */
.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.drop-header-menu .MuiPaper-rounded {
  box-shadow: 10px 10px 20px #0000001a;
  border: 1px solid #e5e7eb;
  margin-top: 13px;
  border-radius: 1px;
}

.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.drop-header-menu .drop-header-menuitem {
  font-size: 12px;
  line-height: 14px;
  color: #6b7280;
  font-family: "Poppins-Regular", Arial, sans-serif;
  border-bottom: 1px solid #e5e7eb;
  padding: 11px 16px;
  text-decoration: none;
}

.text-decoration {
  text-decoration: none;
}

.drop-header-menu ul {
  padding: 0px;
}

.admin-header-main {
  background: linear-gradient(157deg, #05225c, #2c59b1 207%);
  /* background: #18181a; */
  position: fixed;
  top: 0px;
  left: 250px;
  right: 0px;
  z-index: 9;
  padding: 10px 16px 10px 16px;
  border-bottom: 1px solid #e5e7eb;
}

.admin-sidebar-list-main {
  margin-top: 15px;
  padding: 0px 0px 16px 16px;
}

.admin-header-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.admin-header-icon-box {
  margin-right: 11px;
  display: flex;
}

.admin-header-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.admin-header-profile-icon {
  height: 36px;
  width: 36px;
  margin-right: 12px;
  object-fit: cover;
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.flex-drop-main .admin-header-drop {
  font-size: 12px;
  font-family: "Poppins-Regular", Arial, sans-serif;
  line-height: 18px;
  color: #ffffff;
  text-align: start;
}

.flex-drop-main .admin-drop-user {
  font-size: 14px;
  font-family: "Poppins-Bold", Arial, sans-serif;
  line-height: 21px;
  color: #6b7280;
  text-align: start;
}

/* end admin header css */

/* start admin private sale */

.private-sale-fileld {
  width: 620px;
  margin-top: 15px !important;
}

.primary-btn-main .sale-btn,
.primary-btn-main .sale-btn:hover {
  background-color: #05225c;
  padding: 15px;
  font-size: 16px;
  line-height: 19px;
  font-family: "Poppins-Semibold", Arial, sans-serif;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 0 !important;
  border-radius: 5px;
  width: 100%;
}

/* end admin private sale  */
.userbar {
  text-decoration: none;
  color: #000000;
}

/* start admin dashboard page */

.dashboard-content .admin-page-title {
  color: #111827;
  font-size: 26px;
  line-height: 39px;
  font-family: "Poppins-SemiBold", Arial, sans-serif;
}

.admin-dashboard-inner-box {
  border: 1px solid #d1d5db;
  max-width: 245px;
  width: 100%;
  height: 90px;
  border-radius: 6px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
}

.admin-dashboard-inner-box .admin-dash-text {
  color: #6b7280;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

.admin-dashboard-inner-box .admin-dash-price {
  color: #111827;
  font-size: 30px;
  line-height: 46px;
  font-family: "Poppins-Medium", Arial, sans-serif;
}

.save-btn-main .goal-btn {
  min-width: 95px;
  /* margin-right: 10px; */
  background-color: #05225c;
  color: #ffffff;
  min-height: 45px;
  padding: 6px 14px 5px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 40px;
  text-transform: capitalize;
  cursor: pointer;
}

.dash-icons {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.page-table-main .table-container {
  /* padding-bottom: 7px; */
  box-shadow: none;
  max-width: 100vw;
  overflow: auto;
  position: sticky;
  top: 0;
  z-index: 1;
  max-height: 432px;
}

.page-table-main .table {
  min-width: 1100px;
}

.contactrequest-table-main.page-table-main .table-th {
  white-space: nowrap;
}

.page-table-main .table-th {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  background-color: #a6ccff;
  font-family: "Poppins-Medium", Arial, sans-serif;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #d1d5db;
  font-weight: 600;
}

.page-table-main .table .table-td:first-child {
  color: #111827;
}

.page-table-main .table-td {
  font-size: 13px;
  line-height: 19px;
  /* color: #6b7280; */
  color: #000;
  font-family: "Poppins-Regular", Arial, sans-serif;
  padding: 6px 10px 6px 10px;
  border-bottom: 1px solid #d1d5db;
}

.page-table-main .table .action-tab {
  /* display: flex; */
  justify-content: left;
  padding-left: 0px;
}

.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
  width: 10%;
  border-radius: 0;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
  /* width: 10%; */
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
  /* width: 15%; */
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
  /* width: 15%; */
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
  /* width: 10%; */
}

/* .page-table-main .table .table-th:last-child,
.page-table-main .table .table-td:last-child {
  border-radius: 0px 10px 10px 0px;
} */

.admin-sub-title-main .admin-sub-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium", Arial, sans-serif;
}

.view-btn-main .view-order-btn {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
}

.view-btn-main .view-order-btn:hover {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
}

.admin-dashboard-table-row {
  border-bottom: 1px solid #d1d5db;
  padding: 20px 19px;
}

.admin-dash-box {
  border-radius: 6px;
  border: 1px solid #d1d5db;
}

.admin-dashboad-row {
  margin-bottom: 24px;
}

.display-row>* {
  padding: 0px 8px !important;
}

.display-row {
  margin-left: -8px;
  margin-right: -8px;
}

/* end admin dashboard page */

.table-lable-main {
  font-family: "Poppins-Regular", Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
}

.paid-lable-dots {
  background-color: #04ab47;
}

.paid-lable {
  color: #04ab47;
}

.table-lable-dots {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}

.table-lable-flex {
  display: flex;
  align-items: center;
}

.failed-lable-dots {
  background-color: #e4004d;
}

.failed-lable {
  color: #e4004d;
}

.pending-lable-dots {
  background-color: #f39c12;
}

.pending-lable {
  color: #f39c12;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

.cus-page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}

.cus-page-table-main .table-container::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}

.cus-page-table-main .table-container::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}

.cus-page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
  /* background: #a6ccff; */
}

.cus-page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .MuiDataGrid-main::-webkit-scrollbar {
  width: 2px;
  height: 10px;
} */

/* Track */
/* .MuiDataGrid-main::-webkit-scrollbar-track {
  background: red;
  border-radius: 5px;
} */

/* Handle */
/* .MuiDataGrid-main:-webkit-scrollbar-thumb {
  background: red;
} */

/* Handle on hover */
/* .MuiDataGrid-main::-webkit-scrollbar-thumb:hover {
  background: red;
} */
/* start add user page */

.add-user-containt .add-basic-text {
  color: #111827;
  margin: 24px 0px 4px;
}

.add-user-containt .add-section-text {
  color: #6b7280;
  margin-bottom: 20px;
}

.add-user-input .form-control input {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  color: #08080a;
  font-size: 14px;
  line-height: normal;
  border-radius: 0px;
  min-height: 22px;
}

.add-user-input .form-control-textarea {
  min-width: 1015px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  min-height: 100px;
  resize: none;
}

.radio-main .radiogroup .MuiButtonBase-root.Mui-checked {
  color: #004dd1;
}

.radio-main .MuiFormControlLabel-label {
  color: #6b7280;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

.user-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nftStake-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* end add user page */

/*  start dropdown css  */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

/* .dropdown-box .form-control .dropdown-select {
  border: 1px solid #d1d5db;
} */

/* .dropdown-box .form-control svg{
      display: none;
} */
.dropdown-select {
  /* padding: 6px 15px; */
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Poppins-Medium", Arial, sans-serif;
  display: flex;
}

.form-control fieldset {
  border: transparent !important;
  border-radius: 2px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

/* / end dropdown css / */

.discard-btn-main .discard-user-btn,
.discard-btn-main .discard-user-btn:hover {
  min-width: 95px;
  margin-right: 10px;
  min-height: 45px;
  border-radius: 12px;
}

.user-save-icon {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  object-fit: contain;
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
  min-width: 87px;
  min-height: 34px;
}


.admin-tabs-main.css-1ujnqem-MuiTabs-root {
  min-height: 34px;
  /* margin-top: 16px; */
}

.admin-tabs-main .css-jpln7h-MuiTabs-scroller {
  max-width: calc(100vw - 64px);
  overflow: auto !important;
}


.admin-tabs-main .admin-tab {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular", Arial, sans-serif;
  color: #18181a;
  text-transform: capitalize;
  padding: 10px 15px;
  font-weight: 600;
  min-height: 34px;
  /* border-bottom: 2px solid #18181a; */
}

.admin-tabs-main .admin-tab.Mui-selected {
  color: #fff;
  background: transparent linear-gradient(270deg, #f5aa16 0%, #e17025 100%) 0% 0% no-repeat padding-box;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  min-height: 34px;
}

.admin-tabs-main .MuiTabs-indicator {
  background-color: #18181a;
  height: 2px;
}

.admin-tabpanel .edit-highlight-text {
  color: #111827;
  font-family: "Poppins-Medium", Arial, sans-serif;
  margin-bottom: 4px;
}

.admin-tabpanel .edit-para-text {
  margin-bottom: 24px;
  color: #6b7280;
}

.admin-tabpanel>.MuiBox-root {
  padding: 24px 0px;
}

.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  min-width: 18px;
  padding: 0px;
  position: absolute;
  right: -6px;
  bottom: 8px;
  box-shadow: 0px 3px 6px #00000029;
}

.file-upload-btn-main {
  position: relative;
  max-width: max-content;
}

.upload-profile-img {
  height: 100px;
  width: 100px;
  object-fit: contain;
  border: 2px solid #a6ccff;
  border-radius: 8px;
  padding: 6px;
}

.upload-profile-img img {
  padding: 8px;
}

.uploaded-video {
  height: 150px;
  width: 450px;
  object-fit: cover;
}

.upload-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.edit-profile-flex {
  display: grid;
  align-items: end;
  margin-bottom: 8px;
  grid-template-columns: 0.5fr 2fr;
  gap: 10px;
}

/*  */

.user-pass {
  height: 36px;
}

.add-user-data-main {
  padding: 16px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.06),
    0 3px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 8px 0 rgba(0, 0, 0, 0.04);
  background-color: #f8f8f8;
}

/* .goal-setup-css {
  margin-top: 26px;
} */

.goal-setup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 650px;
}

.user-pass fieldset,
.form-group .Mui-focused fieldset {
  border-color: transparent !important;
  border-width: 1px !important;
}

.submit-btn-main .submit-user-btn,
.submit-btn-main .submit-user-btn:hover,
.comman-btn-main .primary-btn,
.comman-btn-main .primary-btn:hover {
  min-width: 95px;
  font-family: "Poppins-Medium", Arial, sans-serif;
  background-color: #05225c;
  color: #ffffff;
  min-height: 36px;
  padding: 6px 14px 5px;
  font-size: 14px;
  border: 1px solid #004dd1;
  line-height: 18px;
  border-radius: 40px;
  text-transform: capitalize;
  cursor: pointer;
}

.comman-btn-main .primary-btn-disable {
  min-width: 95px;
  background-color: #05225c;
  color: #ffffff !important;
  min-height: 36px;
  padding: 6px 14px 5px;
  font-size: 12px;
  border: 1px solid #004dd1;
  line-height: 18px;
  border-radius: 40px;
  text-transform: capitalize;
  cursor: pointer;
}

.dashboard-content .admin-page-title {
  font-size: 26px;
  font-family: "Poppins-SemiBold", Arial, sans-serif;
  color: #18181a;
  font-weight: bold;
  text-shadow: 1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff,
    1px 1px 5px #555;
}

.modal-header .report-modal-title {
  font-size: 20px;
  font-family: "Poppins-SemiBold", Arial, sans-serif;
  color: #05225c;
}

/* edit profile */

.change-pass-main {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 20px;
  max-width: 514px;
  width: 100%;
}

.change-pass-input-box .form-group .form-control input {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%;
  padding: 5.5px 15px;
  background-color: transparent;
}

.change-pass-flex {
  margin-bottom: 20px;
}

.add-user-btn {
  display: flex;
  align-items: center;
  background-color: #05225c;
  padding: 10px;
  border-radius: 12px;
  color: white;
  font-size: 18px;
  line-height: 20px;
  height: 45px;
  text-decoration: none;
  max-width: 100px;
  font-family: "Poppins-Regular", Arial, sans-serif;
  width: 100%;
  justify-content: center;
}

.add-user-btn svg {
  margin-right: 7px;
  height: 22px;
  width: 23px;
  object-fit: contain;
}

.user-data-head .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner {
  width: 100%;
}

/* .user-head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
} */

.user-head-title h2 {
  line-height: 19px;
}

.user-dashboard-link:visited {
  background-color: #f39c12;
  font-size: 50px;
}

.dashboard-header {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium", Arial, sans-serif;
  color: #ffffff;
  display: flex;
  gap: 10px;
  padding: 10px 10px 10px;
  /* margin-bottom: 10px; */
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.add-user-data-main .user-data-head {
  border: none;
}

.menulist .MuiPaper-rounded {
  width: 10ch !important;
  left: 1170px !important;
  top: 260px !important;
  box-shadow: none !important;
}

.menulist .MuiPaper-rounded ul li {
  padding: 8px 12px;
}

.menu-option-li button,
.menu-option-li button:hover {
  color: #001a47;
  background: transparent;
  font-size: 12px;
  line-height: 18px;
  padding: 0;
  font-family: "Poppins-Medium", Arial, sans-serif;
  justify-content: flex-start;
  box-shadow: none;
  text-transform: capitalize;
}

.admin-menu-main .report-post-delete-btn {
  display: none;
}

.swal2-backdrop-show .swal2-show {
  padding: 15px;
}

.swal2-backdrop-show .swal2-icon-show {
  margin-top: 0;
}

.swal2-backdrop-show .swal2-show h2 {
  padding: 10px 0 0;
}

.swal2-backdrop-show .swal2-show .swal2-actions {
  margin: 10px 0 0;
  justify-content: flex-end;
}

.scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1c4;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #d7cfcf40;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.userbar-tabs-main .userbar-tab .tab.Mui-selected {
  color: #7668fe;
}

.userbar-tabs-main .userbar-tab {
  font-size: 16px;
  line-height: 19px;
  font-family: "Poppins-Medium", Arial, sans-serif;
  color: #495463;
  text-transform: capitalize;
  margin-right: 45px;
  min-height: 0px;
  padding-left: 0px;
  min-width: max-content;
  padding-bottom: 10px;
}

.userbar-tabs-main .userbar-tabs {
  min-height: 0px;
  border-bottom: 1px solid #e6effb;
}

.userbar-tabs-main .MuiTabs-indicator {
  background-color: transparent;
  width: 92.6562px !important;
}

.user-data-head .MuiDataGrid-columnHeaderTitle {
  font-size: 15px;
  line-height: 20px;
  color: #495463;
  font-family: "Poppins-Medium", Arial, sans-serif;
  text-overflow: initial;
  overflow: initial;
  white-space: initial;
}

.admin-datepicker-flex .sale-field:first-child {
  margin-right: 20px;
}

.airdrop-section-main .dashboard-left-main {
  display: none;
}

.admin-sidebar-icon {
  height: 20px;
  width: 24px;
  object-fit: contain;
}

.admin-mobile-menu-icon {
  display: none;
}

.admin-sidebar-close {
  height: 20px;
  width: 20px;
  object-fit: contain;
  display: none;
}

.admin-text-field-main {
  margin-bottom: 16px;
  min-height: 65px;
  height: auto;
}

.admin-text-field-main .admin-textfield {
  width: 100%;
}

.admin-text-field-main .admin-textfield input {
  max-width: 506px;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.6) !important;
  border: 0px;
  width: 100%;
}

.admin-text-field-main .admin-textfield .MuiInputBase-root {
  border-radius: 3px;
}

.privat-sale-datepicker input {
  padding: 10px 15px;
}

.admin-textfield .MuiFormLabel-root {
  top: -6px;
}

.primary-btn-main .admin-private-btn {
  margin-top: 0px;
  max-width: 90px;
  height: 40px;
}

.admin-private-main {
  padding: 10px 0px 0px;
}

.admin-sidebar-icons svg {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.token-view-box {
  max-width: 500px;
  width: 100%;
  padding: 25px;
  border: 1px solid #d1d5db;
  border-radius: 10px;
}

.token-view-box-right {
  flex: 1;
}

.token-box-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d5db;
  margin-bottom: 10px;
}

.token-view-box .token-view-lables {
  font-size: 15px;
  line-height: 20px;
  color: #495463;
  font-family: "Poppins-Medium", Arial, sans-serif;
  padding-bottom: 10px;
}

.add-user-input {
  margin-bottom: 16px;
}

.token-view-box .token-view-data {
  font-size: 12px;
  line-height: 18px;
  color: #495463;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

.token-box-flex:last-child {
  margin-bottom: 0px;
}

.add-user-input .form-control {
  width: 100%;
}

.add-user-input .form-control .MuiNativeSelect-select {
  padding: 7.154px 15px;
  border-radius: 3px;
}

.dashboard-content .add-user-page-title {
  margin-bottom: 12px;
  text-transform: capitalize;
}

.dropdown-select input {
  padding: 10.5px 15px 8px !important;
}

.admin-sidebar-list .MuiAccordion-root {
  background-color: transparent;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium", Arial, sans-serif;
  color: #ffffff;
  box-shadow: none;
  justify-content: start;
}

.admin-sidebar-list .MuiAccordionSummary-expandIconWrapper svg {
  color: #fff;
}

.private-sale-usd-box .MuiInput-root::before,
.private-sale-usd-box .MuiInput-root::after {
  border: transparent;
}

.private-sale-usd-box .MuiInputBase-input {
  text-align: right;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Regular";
}

.private-sale-usd-box .MuiInput-root:hover::before {
  border: transparent !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.private-card-wrap {
  flex-wrap: wrap;
}

.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
  outline-width: 0px;
  outline-offset: 0px;
}

/* .user-title-row,
.withdrawal-head-title {
  margin-bottom: 0px;
} */

.admin-sidebar-list .MuiAccordionDetails-root {
  padding: 0px 16px 0px !important;
}

.transcation-popup {
  padding: 8px;
}

.transcation-popup input {
  margin: 8px;
}

.Mui-error {
  margin-left: 0px !important;
}

.dropdown-adduser .MuiSelect-select {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 11.45px 16px;
  background-color: transparent;
  border-radius: 14px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}

.error-text {
  color: red;
  font-family: "Poppins-Regular", Arial, sans-serif;
  font-size: 13px;
  line-height: 15px;
  margin-top: 3px;
}

.admin-forgot-para a {
  color: #000000;
}

.width-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.width-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 27% !important;
  min-width: 27% !important;
  max-width: 27% !important;
}

.roadmap-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.roadmap-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 60% !important;
  min-width: 60% !important;
  max-width: 60% !important;
}

.width-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.width-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 31% !important;
  min-width: 31% !important;
  max-width: 31% !important;
}

.roadmap-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.roadmap-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 23% !important;
  min-width: 23% !important;
  max-width: 23% !important;
}

.width-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.width-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 15.5% !important;
  min-width: 15.5% !important;
  max-width: 15.5% !important;
}

.width-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.width-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 15.5% !important;
  min-width: 15.5% !important;
  max-width: 15.5% !important;
}

.roadmap-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.roadmap-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}

.width-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
.width-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
  width: 7% !important;
  min-width: 7% !important;
  max-width: 7% !important;
}

.refferals-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.refferals-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.refferals-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.refferals-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.refferals-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.refferals-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.refferals-admin-main .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.refferals-admin-main .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 22% !important;
  min-width: 22% !important;
  max-width: 22% !important;
}

.dashboard-content .admin-page-title span {
  font-size: 26px;
  font-family: "Poppins-SemiBold", Arial, sans-serif;
  color: #05225c;
}

.width-admin-main .MuiDataGrid-row,
.width-admin-main [aria-rowindex="1"],
.width-admin-main .MuiDataGrid-virtualScrollerRenderZone,
.roadmap-admin-main .MuiDataGrid-row,
.roadmap-admin-main [aria-rowindex="1"],
.roadmap-admin-main .MuiDataGrid-virtualScrollerRenderZone,
.wallet-table-list .MuiDataGrid-row,
.wallet-table-list [aria-rowindex="1"],
.wallet-table-list .MuiDataGrid-virtualScrollerRenderZone,
.contact-request-list .MuiDataGrid-row,
.contact-request-list [aria-rowindex="1"],
.contact-request-list .MuiDataGrid-virtualScrollerRenderZone,
.warehouse-table-list .MuiDataGrid-row,
.warehouse-table-list [aria-rowindex="1"],
.warehouse-table-list .MuiDataGrid-virtualScrollerRenderZone,
.store-admin-list .MuiDataGrid-row,
.store-admin-list [aria-rowindex="1"],
.store-admin-list .MuiDataGrid-virtualScrollerRenderZone,
.employee-table-list .MuiDataGrid-row,
.employee-table-list [aria-rowindex="1"],
.employee-table-list .MuiDataGrid-virtualScrollerRenderZone,
.store-employee-admin-list .MuiDataGrid-row,
.store-employee-admin-list [aria-rowindex="1"],
.store-employee-admin-list .MuiDataGrid-virtualScrollerRenderZone,
.role-table-list .MuiDataGrid-row,
.role-table-list [aria-rowindex="1"],
.role-table-list .MuiDataGrid-virtualScrollerRenderZone,
.sub-category-table-list .MuiDataGrid-row,
.sub-category-table-list [aria-rowindex="1"],
.sub-category-table-list .MuiDataGrid-virtualScrollerRenderZone,
.product-list-admin-list .MuiDataGrid-row,
.product-list-admin-list [aria-rowindex="1"],
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone {
  width: 100% !important;
}

.user-row {
  margin-left: -10px;
  margin-right: -10px;
}

.user-row .user-grid-column {
  padding: 0px 10px;
}

.add-user-height .MuiDataGrid-virtualScroller {
  overflow-x: auto !important;
}

.admin-table .MuiDataGrid-virtualScroller {
  overflow: initial !important;
}

.user-details-main-content .details-flex-main-user {
  display: flex;
  word-break: break-all;
  align-items: start;
  padding: 5px 0px;
  margin: 3px 0px;
}

.user-details-main-content .card-main-details-user {
  border: 1px solid #a6ccff;
  border-radius: 6px;
  margin-bottom: 10px;
}

.user-details-main-content .title-user-details-content {
  background: #a6ccff;
  border-radius: 6px 6px 0px 0px;
  padding: 10px;
}

.user-details-main-content .card-inner-details {
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
}

.details-flex-main-user .col1-user-main-content {
  min-width: 200px;
  font-size: 14px;
  font-family: "Poppins-SemiBold", Arial, sans-serif;
}

/* .user-row-details .user-grid-column .details-flex-main-user:last-child {
  margin-bottom: 0px;
} */

.details-flex-main-user .col2-user-main-content {
  font-size: 14px;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

.title-user-details-content p {
  color: #000;
  font-family: "Poppins-SemiBold", Arial, sans-serif;
}

.setting-title {
  color: #111827 !important;
  font-size: 26px !important;
  line-height: 39px !important;
  font-family: "Poppins-SemiBold", Arial, sans-serif !important;
  margin-bottom: 13px !important;
  margin-top: 13px !important;
}

.post-style {
  text-transform: capitalize;
}

.user-name {
  text-transform: capitalize;
  cursor: pointer;
}

.wallet-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.wallet-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}

.wallet-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.wallet-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 35% !important;
  min-width: 35% !important;
  max-width: 35% !important;
}

.wallet-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.wallet-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.wallet-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.wallet-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important;
}

/* .wallet-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
.wallet-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
} */

.contact-request-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}

.contact-request-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 28% !important;
  min-width: 28% !important;
  max-width: 28% !important;
}

/* .contact-request-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3), .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 32% !important;
  min-width: 32% !important;
  max-width: 32% !important;
  
} */
.contact-request-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.contact-request-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important;
}

.contact-request-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.no-text-decoration {
  text-decoration: none;
}

.warehouse-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.warehouse-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 13% !important;
  min-width: 13% !important;
  max-width: 13% !important;
}

.warehouse-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.warehouse-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 17% !important;
  min-width: 17% !important;
  max-width: 17% !important;
}

.warehouse-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.warehouse-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 18% !important;
  min-width: 18% !important;
  max-width: 18% !important;
}

.warehouse-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.warehouse-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}

.warehouse-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
.warehouse-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
  width: 19% !important;
  min-width: 19% !important;
  max-width: 19% !important;
}

.warehouse-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
.warehouse-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
}

.warehouse-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
.warehouse-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
  width: 8% !important;
  min-width: 8% !important;
  max-width: 8% !important;
}

.employee-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.employee-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 12% !important;
  min-width: 12% !important;
  max-width: 12% !important;
}

.employee-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.employee-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 16% !important;
  min-width: 16% !important;
  max-width: 16% !important;
}

.employee-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.employee-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.employee-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.employee-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}

.employee-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
.employee-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.employee-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
.employee-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
  width: 9% !important;
  min-width: 9% !important;
  max-width: 9% !important;
}

.employee-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
.employee-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
  width: 8% !important;
  min-width: 8% !important;
  max-width: 8% !important;
}

.warehouse-list-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.store-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.store-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 11% !important;
  min-width: 11% !important;
  max-width: 11% !important;
}

.store-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.store-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 16% !important;
  min-width: 16% !important;
  max-width: 16% !important;
}

.store-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.store-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.store-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.store-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}

.store-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
.store-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.store-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
.store-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
}

.store-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
.store-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
  width: 8% !important;
  min-width: 8% !important;
  max-width: 8% !important;
}

.role-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.role-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.role-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.role-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.role-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.role-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.role-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.role-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.store-add-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.store-employee-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.store-employee-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.store-employee-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.store-employee-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.store-employee-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.store-employee-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.store-employee-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.store-employee-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.store-employee-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
.store-employee-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.active-store {
  width: 70px;
  height: 30px;
  border-radius: 3px;
  padding: 2px;
  color: #fff;
  background-color: red;
}

.active-store-green {
  background-color: green;
}

.active-store-red {
  background-color: red;
}

.sub-category-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.sub-category-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.sub-category-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.sub-category-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
}

.sub-category-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.sub-category-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.sub-category-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.sub-category-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.sub-category-table-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
.sub-category-table-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(1),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
  width: 11% !important;
  min-width: 11% !important;
  max-width: 11% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(2),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(2) {
  width: 9% !important;
  min-width: 9% !important;
  max-width: 9% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(3),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(3) {
  width: 14% !important;
  min-width: 14% !important;
  max-width: 14% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(4),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(4) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(5),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(5) {
  width: 15% !important;
  min-width: 15% !important;
  max-width: 15% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(6),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(6) {
  width: 12% !important;
  min-width: 12% !important;
  max-width: 12% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(7),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(7) {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(8),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(8) {
  width: 8% !important;
  min-width: 8% !important;
  max-width: 8% !important;
}

.product-list-admin-list .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:nth-child(9),
.product-list-admin-list .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell:nth-child(9) {
  width: 7% !important;
  min-width: 7% !important;
  max-width: 7% !important;
}

.ck-editor__editable {
  min-height: 300px !important;
}

.badge {
  color: #fff;
  display: inline-block;
  padding: 0.01em 0.4em;
  font-size: 12px;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-active {
  background-color: #008000;
}

.badge-deactive {
  background-color: #ff0000;
}

/* start user list page */
.userview-image img {
  height: 100px;
  width: 100px;
}

.user-detail-label {
  font-size: 15px;
  font-weight: 500;
  color: #111827;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

.user-view-value {
  font-size: 15px;
  font-weight: 500;
  margin-left: 15px;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

.userdata-btn-flex {
  display: flex;
}

.userdata-btn-flex button {
  min-width: 30px;
}

.userlist-data {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: start;
}

.userlist-img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  object-fit: cover;
}

.userlist-table-main .table {
  margin: 0px;
}

/* .userlist-table-main .table .table-th:first-child {
  text-align: center;
} */
.contactrequest-table-main .table {
  min-width: 900px;
}

.contactrequest-table-main .table .table-th:nth-child(1),
.contactrequest-table-main .table .table-td:nth-child(1) {
  width: 15%;
  /* border-radius: 10px 0px 0px 10px; */
}

.contactrequest-table-main .table .table-th:nth-child(2),
.contactrequest-table-main .table .table-td:nth-child(2) {
  width: 20%;
}

.contactrequest-table-main .table .table-th:nth-child(3),
.contactrequest-table-main .table .table-td:nth-child(3) {
  width: 40%;
}

.contactrequest-table-main .table .table-th:nth-child(4),
.contactrequest-table-main .table .table-td:nth-child(4) {
  width: 25%;
}

/* NFT STAKE TABLE */
.nftStake-table-main .table {
  min-width: 900px;
}

.nftStake-table-main .table .table-th:nth-child(1),
.nftStake-table-main .table .table-td:nth-child(1) {
  width: 15%;
  /* border-radius: 10px 0px 0px 10px; */
}

.nftStake-table-main .table .table-th:nth-child(2),
.nftStake-table-main .table .table-td:nth-child(2) {
  width: 20%;
}

.nftStake-table-main .table .table-th:nth-child(3),
.nftStake-table-main .table .table-td:nth-child(3) {
  width: 10%;
}

.nftStake-table-main .table .table-th:nth-child(4),
.nftStake-table-main .table .table-td:nth-child(4) {
  width: 25%;
}

/* NFT STAKE TABLE */
/* gym list table  */
.gymList-table-main .table {
  min-width: 1500px;
}

.gymList-table-main .table .table-th:nth-child(1),
.gymList-table-main .table .table-td:nth-child(1) {
  /* border-radius: 10px 0px 0px 10px; */
}

.gymList-table-main .table .table-th:last-child,
.gymList-table-main .table .table-td:last-child {
  border-radius: 0px 10px 10px 0px;
}

/* gym list table  */
/* PRODUCT LIST TABLE  */
.productList-table-main .table {
  min-width: 950px;
}

/* PRODUCT LIST TABLE  */

/* user list reffer table  */
.user-list-referral-table .table {
  min-width: 900px;
}

.user-list-referral-table .table .table-th:nth-child(1),
.user-list-referral-table .table .table-td:nth-child(1) {
  width: 20%;
  /* border-radius: 10px 0px 0px 10px; */
}

.user-list-referral-table .table .table-th:nth-child(2),
.user-list-referral-table .table .table-td:nth-child(2) {
  width: 30%;
}

.user-list-referral-table .table .table-th:nth-child(3),
.user-list-referral-table .table .table-td:nth-child(3) {
  width: 25%;
}

.user-list-referral-table .table .table-th:nth-child(4),
.user-list-referral-table .table .table-td:nth-child(4) {
  width: 30%;
}

/* user list reffer table  */

.warehouse-table-main .table .table-th:nth-child(1),
.warehouse-table-main .table .table-td:nth-child(1) {
  width: 10%;
  /* border-radius: 10px 0px 0px 10px; */
}

.warehouse-table-main .table .table-th:nth-child(2),
.warehouse-table-main .table .table-td:nth-child(2) {
  width: 20%;
}

.warehouse-table-main .table .table-th:nth-child(3),
.warehouse-table-main .table .table-td:nth-child(3) {
  width: 20%;
}

.warehouse-table-main .table .table-th:nth-child(4),
.warehouse-table-main .table .table-td:nth-child(4) {
  width: 20%;
}

.warehouse-table-main .table .table-th:nth-child(5),
.warehouse-table-main .table .table-td:nth-child(5) {
  width: 20%;
}

.warehouse-table-main .table .table-th:nth-child(6),
.warehouse-table-main .table .table-td:nth-child(6) {
  width: 10%;
}

.category-table-main-table-main .table .table-th:nth-child(1),
.category-table-main-table-main .table .table-td:nth-child(1) {
  width: 25%;
}

.category-table-main-table-main .table .table-th:nth-child(2),
.category-table-main-table-main .table .table-td:nth-child(2) {
  width: 25%;
}

.category-table-main-table-main .table .table-th:nth-child(3),
.category-table-main-table-main .table .table-td:nth-child(3) {
  width: 25%;
}

.category-table-main-table-main .table .table-th:nth-child(4),
.category-table-main-table-main .table .table-td:nth-child(4) {
  width: 25%;
}

.product-table-main .table .table-th:nth-child(1),
.product-table-main .table .table-td:nth-child(1) {
  width: 9%;
  /* border-radius: 10px 0px 0px 10px; */
}

.product-table-main .table .table-th:nth-child(2),
.product-table-main .table .table-td:nth-child(2) {
  width: 9%;
}

.product-table-main .table .table-th:nth-child(3),
.product-table-main .table .table-td:nth-child(3) {
  width: 9%;
}

.product-table-main .table .table-th:nth-child(4),
.product-table-main .table .table-td:nth-child(4) {
  width: 9%;
}

.product-table-main .table .table-th:nth-child(5),
.product-table-main .table .table-td:nth-child(5) {
  width: 9%;
}

.product-table-main .table .table-th:nth-child(6),
.product-table-main .table .table-td:nth-child(6) {
  width: 9%;
}

.product-table-main .table .table-th:nth-child(7),
.product-table-main .table .table-td:nth-child(7) {
  width: 9%;
}

.product-table-main .table .table-th:nth-child(8),
.product-table-main .table .table-td:nth-child(8) {
  width: 9%;
}

.product-table-main .table .table-th:nth-child(9),
.product-table-main .table .table-td:nth-child(9) {
  width: 9%;
}

.product-table-main .table .table-th:last-child,
.product-table-main .table .table-td:last-child {
  border-radius: 0px 10px 10px 0px;
}

.down-icon,
.plus-icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 9px;
}

.export-btn-main .export-btn,
.export-btn-main .export-btn:hover {
  margin-right: 10px;
  height: 36px;
  min-width: 96px;
}

.adduser-btn-main .adduser-btn,
.adduser-btn-main .adduser-btn:hover {
  min-width: 115px;
  height: 36px;
}

.user-search-box {
  position: relative;
  /* margin-right: 10px; */
}

.singal-user-search-box {
  display: none;
}

.user-search-box .form-control input {
  padding: 5px 16px 5px 32px !important;
  border-radius: 40px;
}

.user-search-box .form-group {
  display: flex;
}

.user-search-box .form-control input::placeholder {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular", Arial, sans-serif;
  opacity: 1;
}

.search-grey-img {
  position: absolute;
  left: 8px;
  height: 100%;
  width: 14px;
  top: 50%;
  transform: translateY(-50%);
  filter: brightness(0.5);
}

.user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.challanges-heading-right-side-data {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.userlist-btn-flex {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.userlist-type-box {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.userlist-type-box span {
  display: flex;
  align-items: center;
}

.userlist-type-box .goal-setup-type-btn {
  padding: 0px 10px;
  border-radius: 40px;
  min-height: 34px;
  text-transform: capitalize;
  background-color: #05225c;
  color: #fff;
}

.userlist-type-box .goal-setup-type-btn:hover {
  text-decoration: none;
  background-color: #1565c0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.user-list-flex .user-list-page-title {
  margin-bottom: 0px;
  text-transform: capitalize;
}

/* user list page end */

.user-goal-setup-table-main .table {
  min-width: 1000px;
}

.user-goal-setup-table-main .table .table-th:nth-child(1),
.user-goal-setup-table-main .table .table-td:nth-child(1) {
  width: 10%;
  /* border-radius: 10px 0px 0px 10px; */
}

.user-goal-setup-table-main .table .table-th:nth-child(2),
.user-goal-setup-table-main .table .table-td:nth-child(2) {
  width: 10%;
}

.user-goal-setup-table-main .table .table-th:nth-child(3),
.user-goal-setup-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.user-goal-setup-table-main .table .table-th:last-child,
.user-goal-setup-table-main .table .table-td:last-child {
  width: 10%;
  /* border-radius: 0px 10px 10px 0px; */
}

.table-td .table-modal-btn,
.table-td .table-modal-btn:hover {
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular", Arial, sans-serif;
  background-color: transparent;
}

/* .admin-setup-goal-row {
  margin-top: 20px;
} */

/* goal setup table  */
.goal-setup-table .table {
  min-width: 1000px;
}

.min-max-tab {
  text-align: center;
}

.goal-setup-table .table .table-th {
  background-color: gray;
}

.goal-setup-table .table .table-th:nth-child(3),
.goal-setup-table .table .table-td:nth-child(3) {
  /* width: 12%; */
}

.goal-setup-table .table .table-th:nth-child(4),
.goal-setup-table .table .table-td:nth-child(4) {
  /* width: 15%; */
}

.goal-setup-table .table .table-th:nth-child(5),
.goal-setup-table .table .table-td:nth-child(5) {
  /* width: 17%; */
}

/* goal setup table  */
/* NFT setup table  */
.nft-setup-table .table {
  min-width: 1100px;
}

/* .min-max-tab {
  text-align: center;
} */
.table .table-head .table-row th {
  /* background: #a6ccff;
  padding: 20px 9px;
  white-space: nowrap; */
  font-size: 14px;
  line-height: 20px;
  color: #000;
  background-color: #a6ccff;
  font-family: "Poppins-Medium", Arial, sans-serif;
  padding: 10px 10px 10px 10px;
  border-radius: 0;
  font-weight: 600;
  white-space: nowrap;
}

/* .nft-setup-table .table .table-th:nth-child(1),
.nft-setup-table .table .table-td:nth-child(1) {
  border-radius: 10px 0px 0px 10px !important;
} */

.nft-setup-table .table .table-th:nth-child(2),
.nft-setup-table .table .table-td:nth-child(2) {
  width: 12%;
}

.nft-setup-table .table .table-th:nth-child(3),
.nft-setup-table .table .table-td:nth-child(3) {
  width: 13%;
}

.nft-setup-table .table .table-th:nth-child(4),
.nft-setup-table .table .table-td:nth-child(4) {
  width: 13%;
}

.nft-setup-table .table .table-th:nth-child(5),
.nft-setup-table .table .table-td:nth-child(5) {
  width: 15%;
}

/* NFT setup table  */

/* community setup table  */
.communitygroup-table-main .table {
  min-width: 900px;
}

.min-max-tab {
  text-align: center;
}

.communitygroup-table-main .table .table-th:nth-child(1),
.communitygroup-table-main .table .table-td:nth-child(1) {
  width: 7%;
  /* border-radius: 10px 0px 0px 10px; */
}

.communitygroup-table-main .table .table-th:nth-child(2),
.communitygroup-table-main .table .table-td:nth-child(2) {
  width: 15%;
}

.communitygroup-table-main .table .table-th:nth-child(3),
.communitygroup-table-main .table .table-td:nth-child(3) {
  width: 18%;
}

.communitygroup-table-main .table .table-th:nth-child(4),
.communitygroup-table-main .table .table-td:nth-child(4) {
  width: 20%;
}

.communitygroup-table-main .table .table-th:nth-child(5),
.communitygroup-table-main .table .table-td:nth-child(5) {
  width: 20%;
}

/* .communitygroup-table-main .table .table-th:nth-child(6),
.communitygroup-table-main .table .table-td:nth-child(6) {
  width: 15%;
} */

/* community setup table  */
/* CHALLANGE TABLE  */
.challange-table-main .table {
  min-width: 2000px;
}

.min-max-tab {
  text-align: center;
}

.challange-table-main .table .table-th:nth-child(1),
.challange-table-main .table .table-td:nth-child(1) {
  width: 4%;
  /* border-radius: 10px 0px 0px 10px; */
  padding-left: 15px;
}

.challange-table-main .table .table-th:nth-child(2),
.challange-table-main .table .table-td:nth-child(2) {
  width: 5%;
}

.challange-table-main .table .table-th:nth-child(3),
.challange-table-main .table .table-td:nth-child(3) {
  width: 5%;
}

.challange-table-main .table .table-th:nth-child(4),
.challange-table-main .table .table-td:nth-child(4) {
  /* width: 13%; */
  width: 7%;
}

.challange-table-main .table .table-th:nth-child(5),
.challange-table-main .table .table-td:nth-child(5) {
  /* width: 12%; */
  padding-left: 20px;
}

.challange-table-main .table .table-th:nth-child(6),
.challange-table-main .table .table-td:nth-child(6) {
  /* width: 12%; */
}

.challange-table-main .table .table-th:nth-child(7),
.challange-table-main .table .table-td:nth-child(7) {
  /* width: 10%; */
}

.challange-table-main .table .table-th:nth-child(8),
.challange-table-main .table .table-td:nth-child(8) {
  /* width: 10%; */
}

.challange-table-main .table .table-th:nth-child(9),
.challange-table-main .table .table-td:nth-child(9) {
  /* width: 10%; */
}

.challange-table-main .table .table-th:nth-child(10),
.challange-table-main .table .table-td:nth-child(10) {
  /* width: 15%; */
}

.challange-table-main .table .table-th:nth-child(11),
.challange-table-main .table .table-td:nth-child(11) {
  /* width: 15%; */
}

.challange-table-main .table .table-th:nth-child(12),
.challange-table-main .table .table-td:nth-child(12) {
  /* width: 15%; */
}

.challange-table-main .table .table-th:nth-child(13),
.challange-table-main .table .table-td:nth-child(13) {
  /* width: 15%; */
}

.challange-table-main .table .table-th:nth-child(14),
.challange-table-main .table .table-td:nth-child(14) {
  /* width: 15%; */
}

/* CHALLANGE TABLE  */

/* user-post-table */
.user-post-table .table {
  min-width: 900px;
}

/* .min-max-tab {
  text-align: center;
} */
.user-post-table .table .table-th:nth-child(1),
.user-post-table .table .table-td:nth-child(1) {
  width: 7%;
  /* border-radius: 10px 0px 0px 10px; */
}

.user-post-table .table .table-th:nth-child(2),
.user-post-table .table .table-td:nth-child(2) {
  width: 13%;
}

.user-post-table .table .table-th:nth-child(3),
.user-post-table .table .table-td:nth-child(3) {
  width: 15%;
}

.user-post-table .table .table-th:nth-child(3),
.user-post-table .table .table-td:nth-child(3) {
  width: 15%;
}

.user-post-table .table .table-th:nth-child(4),
.user-post-table .table .table-td:nth-child(4) {
  width: 15%;
  /* padding-right: 55px; */
  /* background-color: gray; */
}

.user-post-table .table .likes-th:nth-child(4),
.user-post-table .table .likes-td:nth-child(4) {
  width: 20%;
  /* background-color: gray; */
}

.user-post-table .table .table-th:nth-child(5),
.user-post-table.table .table-td:nth-child(5) {
  width: 20%;
}

.user-post-table .table .table-th:nth-child(6),
.user-post-table .table .table-td:nth-child(6) {
  width: 20%;
}

/* user-post-table */

/* user goal modal table  */
/* .user-goal-modal-table */
.user-goal-modal-table .table {
  min-width: 700px;
}

.user-goal-modal-table .table .table-th:nth-child(1),
.user-goal-modal-table .table .table-td:nth-child(1) {
  width: 10%;
}

.user-goal-modal-table .table .table-th:nth-child(2),
.user-goal-modal-table .table .table-td:nth-child(2) {
  width: 15%;
}

.user-goal-modal-table .table .table-th:nth-child(3),
.user-goal-modal-table .table .table-td:nth-child(3) {
  width: 18%;
}

.user-goal-modal-table .table .table-th:nth-child(4),
.user-goal-modal-table .table .table-td:nth-child(4) {
  width: 20%;
}

/* .user-goal-modal-table .table .table-th:nth-child(5),
.user-goal-modal-table .table .table-td:nth-child(5) {
  width: 20%;
} */
.table-head .report-on-specific-post-post-header-col {
  display: none;
}

.table-body .report-on-specific-post-post-col {
  display: none;
}

.report-modal .modal-inner {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.06),
    0 3px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 8px 0 rgba(0, 0, 0, 0.04);
  max-height: calc(100% - 100px);
  overflow: auto;
  padding: 20px;
}

.admin-menu-main .delete_specific-btn {
  display: none;
}

/* user goal modal table  */

.goal-setup-data-flex {
  display: flex;
  align-items: center;
  width: 40px;
}

/* post css  */
.user-container-box {
  display: flex;
  justify-content: center;
}

.user-container {
  width: 500px;
  border-radius: 8px;
}

.user-card {
  margin: 10px;
  /* border: 1px solid black; */
}

.user-post-img-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.image-description-class {
  display: flex;
  align-items: center;
}

/* .user-post-circular-box {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: inline-block;
} */
.user-post-user-img {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  display: inline-block;
}

.trainer-product-crypto-img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  padding-top: 5px;
  margin-left: 5px;
}

.user-post-user-detail {
  margin-left: 10px;
}

.user-post-user-detail .user-post-user-name {
  font-size: 13px;
  line-height: 17px;
  color: #111827;
  font-family: "Poppins-Medium", Arial, sans-serif;
  text-transform: capitalize;
  cursor: pointer;
}

.user-post-user-detail .user-post-user-time {
  font-size: 10px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular", Arial, sans-serif;
}

.user-post-post-img-box {
  width: 100%;
  /* height: 200px; */
}

.trainer-details-content {
  margin-top: 5px;
}

.user-post-post-img-box .user-post-post-img {
  width: 100%;
  border-radius: 6px;
  height: 100%;
  object-fit: cover;
}

.user-post-post-img-box .view-post-post-img {
  /* width: 100%; */
  border-radius: 6px;
  height: 300px;
  object-fit: contain;
  /* aspect-ratio: 1 / 1; */
  object-position: top;
  border: 1px solid #d9d9d9;
}

.user-post-post-img-box .view-post-post-img img {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
}

.user-post-error-mes {
  display: block;
  margin: auto;
}

/* .post-main {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 10px;
} */

.user-post-likes .user-post-description-text {
  font-size: 14px;
  line-height: 26px;
  color: #6b7280;
  font-family: "Poppins-Regular", Arial, sans-serif;
  /* height: 50px; */
  font-weight: bold;
}

.user-post-likes .user-post-like-icon {
  font-size: 14px;
  line-height: 22px;
  padding-top: 3px;
}

.user-post-description .user-post-description-text {
  font-size: 13px;
  margin-top: 8px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular", Arial, sans-serif;
  /* height: 50px; */
  word-break: break-all;
}

.user-post-pagination-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.user-post-pagination>.css-wjh20t-MuiPagination-ul>li>.css-1itoclz-MuiButtonBase-root-MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular", Arial, sans-serif;
  margin: 0 4px;
}

.view-post-comments-box {
  /* margin-left: 20px; */
  /* width: 400px; */
  padding: 16px;
}

.posts-data {
  background-color: red;
}

.communityTabOptionalMenuBox {
  display: none;
}

.optional-frontend-pagination {
  display: none;
}

/* post css  */

/* .searchbar {
    border: 1px solid #d4d9e1;
    width: 200px !important;
    height: 36px;
    line-height: 31px;
    border-radius: 0px !important;
    margin-right: 0px !important;
    background-color: #fff !important;
}

.searchbar input::placeholder {
    color: #919cac;
    opacity: 1;
}

.searchbar input {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 14px 6px 6px !important;
    font-family: "poppins-regular";
} */

.bottom-border {
  border: 1px solid #fff;
}

.table-head-sticky thead {
  position: sticky;
  top: 0;
  z-index: 1;
  max-height: 180px;
}

.form-back-btn {
  text-align: end;
}

.form-back-btn button {
  /* background: transparent linear-gradient(270deg, #f5aa16 0%, #e17025 100%) 0% 0% no-repeat padding-box; */
  background-color: #05225c;
  min-width: auto;
  padding: 6px;
  border-radius: 6px;
}

.form-back-btn button svg {
  fill: #fff;
}

.submit-btn-main {
  text-align: center;
}

.switchcolor .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #05225c;
}

.title-list-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.rc-time-picker {
  width: 100%;
}

.rc-time-picker-input {
  border: 1px solid #a6ccff !important;
  width: 100% !important;
  padding: 6px 12px !important;
  background-color: #fff !important;
  color: #08080a !important;
  font-size: 14px !important;
  line-height: normal !important;
  border-radius: 0px !important;
  height: 36px !important;
  caret-color: transparent !important;
}

.rc-time-picker-clear-icon:after {
  font-size: 14px !important;
  color: #aaa !important;
  line-height: 2 !important;
  font-weight: bold !important;
}

.switchcolor.switchbox {
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

th.refferal-cell-table span {
  padding-right: 0px;
}

.email-refferal span,
.prices-refferal span {
  padding: 4px;
  padding-right: 0px;
}

.email-refferal svg,
.prices-refferal svg,
.refferal-cell-table svg {
  fill: #032058;
}

a.sub-menu.admin-sidebar-link {
  width: 160px;
}

.userlist-btn-flex.w-auto {
  width: auto;
}

.form-control.text-area-box {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  border-radius: 0px;
  color: #08080a;
  font-size: 14px;
  line-height: normal;
  margin-top: 10px;
}

.table-td.fw-bold.pr-30,
.table-td.pr-30,
.table-th.pr-30 {
  padding-right: 30px;
}

.btm-bg-color {
  background: #05225c;
  color: #fff !important;
  font-size: 14px !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list,
.css-r8u8y9 {
  max-height: 150px !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border: 1px solid #a6ccff;
  border-radius: 0px;
}

.add-user-data-main .grid-main form .display-row .grid-column {
  height: 100%;
  display: block;
}

.close-btn-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}

.close-btn-box h4 {
  padding: 16px;
}

.like-post-box ul {
  max-height: 380px;
  overflow-y: auto;
}

.user-modal-likebox {
  display: flex;
  flex-direction: column;
}

.user-modal-likebox .css-tlelie-MuiListItemText-root {
  margin-top: 0;
  margin-bottom: 0;
}

.user-modal-likebox span {
  font-size: 14px;
}

.delete-modal-inner-main.modal-inner {
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.delete-modal-cont {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.fnt-bold span {
  font-weight: bold;
}

.hsn-lable {
  font-size: 12px;
}

.text-danger {
  color: #ff0000;
}

.comman-modal-body.fix-height-modal {
  max-height: 320px;
  overflow: auto;
  scrollbar-width: thin;
  word-break: break-all;
}

.comman-modal-body.fix-height-modal::-webkit-scrollbar {
  width: 8px;
}

.drop-main-comman .dropdown-content.disable-dropdown {
  border: 1px solid #d9d9d9;
}

.drop-main-comman .dropdown-content.enable-dropdown {
  border: 1px solid #a6ccff;
}

.nft-setup-table.page-table-main.order-rating-list .table {
  min-width: 900px !important;
}

.nft-setup-table.page-table-main.order-rating-list .table .table-th:nth-child(5),
.nft-setup-table .table .table-td:nth-child(5) {
  width: 15%;
}

.nft-setup-table.page-table-main.order-rating-list .table .table-th:nth-child(6),
.nft-setup-table .table .table-td:nth-child(6) {
  width: 15%;
}

.submit-btn-main.border-btn-main.mt-16.cancel-btn-main {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.user-edit-show-document {
  position: relative;
}

.product-multi-img {
  position: relative;
  height: 160px;
  width: 100%;
  border-radius: 10px;
}

.product-multi-img img {
  object-fit: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.refresh-btn-main.close-share-btn.product-close {
  position: absolute;
  right: 5px;
  top: 0;
}

.userdetail-img button {
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.userdetail-img img {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  border: 1px solid #dedede;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.w-100 {
  width: 100%;
}

.tab-button.css-1lf7vnj-JoyTab-root::after,
.tab-button.css-tvnyb6-JoyTab-root::after {
  height: auto;
}

.datepicker-wrap .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0;
}

.drop-main-comman #demo-simple-select {
  display: block;
  vertical-align: middle;
  text-transform: capitalize;
}


.border-0 {
  border: 0 !important;
}

.td-w-22 {
  width: 22% !important;
}

.leftbox-image-box.session-box {
  max-width: 30%;
  width: 100%;
  flex: 0 0 30%;
}

.input-details-main.num-main-input .country-mobile-box {
  border: 1px solid #a6ccff;
  width: 100%;
  padding: 2px 12px;
  background-color: #fff;
  color: #08080a;
  font-size: 14px;
  line-height: normal;
  border-radius: 0px;
  min-height: 22px;
}

.input-details-main.num-main-input .css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  border: 0;
}

.input-details-main.num-main-input .css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
  border: 0;
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled,
  .Mui-error):before {
  border-bottom: 0 !important;
}

.siggle-group-flex video {
  margin-top: 26px;
}

.singlegroup-tag-name-detail.w-400 {
  max-width: 100%;
  width: 400px;
}

.nft-setting-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.market-place-box input {
  margin-top: 8px;
  margin-bottom: 8px;
}

.market-error-msg {
  color: red;
  font-size: 0.8rem;
  margin-top: 4px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(25, 118, 210, 0.2) !important;
}

.dropdown-select.multi-select-value.dropdown-select .MuiSelect-select {
  height: auto !important;
  white-space: normal !important;
  padding-right: 30px !important;
}

.add-user-input .form-control input::placeholder {
  font: inherit !important;
  letter-spacing: inherit;
  color: rgb(184, 184, 184);
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  font-weight: 300 !important;
}

.footer-link {
  display: flex;
  gap: 8px;
  justify-content: center;
  text-decoration: none !important;
}

.footer-about-title {
  font-size: 14px;
  line-height: 20px;
  color: #08090b;
  font-family: "Poppins-Medium";
  display: block;
  text-align: start;
  text-decoration: none !important;
}

.page-cont-box {
  justify-content: center !important;
  margin-top: 26px;
  margin-bottom: 26px;
}

.page-cont-box h3 {
  font-size: 26px;
  font-family: "Poppins-SemiBold", Arial, sans-serif;
  color: #18181a;
  font-weight: bold;
  text-shadow: 1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff,
    1px 1px 5px #555;
  margin-bottom: 26px;
}

.winner-star {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.winner-star svg {
  fill: #05225c;
}

.save-btn-main.singleNFt-back-btn-box {
  display: flex;
  justify-content: end;
}

.contdetail-box {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
  background-color: rgb(255, 255, 255);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 100%;
}

.contdetail-box.bg-border-box .cont-detail {
  border: 1px solid #d9d9d9;
  height: 100%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.contdetail-title {
  /* background-image: linear-gradient(157deg, #05225c, #2c59b1 207%); */
  background: transparent linear-gradient(270deg, #f5aa16 0%, #e17025 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 6px 8px;
  font-weight: bold;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.contdetail-title h6 {
  font-size: 16px !important;
  line-height: normal !important;
}

.cont-detail {
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 6px 8px;
}


.cont-detail-list {
  /* padding: 10px 20px; */
  list-style: none;
}

.cont-detail-list>li {
  display: flex;
  gap: 15px;
  margin-bottom: 4px;
  /* padding: 5px 0px; */
}

.cont-detail-tag-name {
  font-size: 14px;
  word-wrap: break-word;
  width: 105px;
}

.cont-detail-tag-name-detail {
  font-size: 14px;
  word-wrap: break-word;
  width: 200px;
}

.accordion-title svg {
  fill: #fff;
}

.accordion-title {
  background: linear-gradient(157deg, #05225c, #2c59b1 207%) !important;
  color: #fff !important;
  margin-top: 16px !important;
}

.cont-detail .css-15v22id-MuiAccordionDetails-root {
  background: aliceblue;
}

.cont-detail .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px;
}

.cont-detail .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0;
}

.cont-detail-profile {
  width: 56px;
  height: 56px;
  border: 1px solid #dedede;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 6px;
}

.cont-multiple-img {
  width: 100px;
  height: 100px;
  border: 1px solid #dedede;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 6px;
}

.cont-multiple-video {
  width: 100%;
  height: 100px;
  border: 1px solid #dedede;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 6px;
}

.cont-multiple-video video {
  margin-top: 0px;
}

.cont-detail-profile img,
.cont-multiple-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cont-detail-list.cln-count-2 {
  column-count: 2;
}

.flex-box {
  display: flex;
  gap: 8px;
}

.cont-detail.cont-detail-video-img {
  display: flex;
  justify-content: space-between;
}

.cont-detail.cont-detail-video-img .cont-detail-tag-name {
  width: 150px;
}

.cont-detail.cont-detail-video-img .cont-detail-tag-name-detail {
  width: 250px;
}

a.btn.icon-box.notify {
  color: #fff !important;
  margin-right: 8px;
  display: flex;
}

a.btn.icon-box.notify .css-i4bv87-MuiSvgIcon-root {
  width: 1.5em;
  height: 1.5em;
}

.placeholder-select {
  font: inherit !important;
  letter-spacing: inherit;
  color: rgb(184, 184, 184);
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  font-weight: 300 !important;
}

/* Notification Page */
.notification-main-box {
  position: relative;
  border: 1px solid #005c8a;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
}

.notification-box {
  display: grid;
  grid-template-columns: 0.2fr 5fr 0.5fr;
  gap: 16px;
  align-items: center;
}

.notification-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.notification-img img {
  height: 100%;
  width: 100%;

}

.notification-text {
  font-size: 16px;
  font-weight: bold;
}

.notification-sub-text,
.notification-time {
  font-size: 14px;
}

/* End Notification Page */
/* Modal Box */

.ticket-disc-box {
  display: flex;
  word-break: break-all;
}

.ticket-disc-box .trainer-detail-fullname-value-rightside {
  width: 100%;
}

td.table-td.token-tab::first-letter {
  text-transform: uppercase;
}

.drop-main-comman p {
  /* white-space: break-spaces; */
  word-break: break-word;
  margin-right: 10px;
}

.time-zone {
  font-size: 13px;
  line-height: 19px;
  color: #000;
  font-family: "Poppins-Regular", Arial, sans-serif;
  text-transform: uppercase;
  padding: 6px 10px 6px 10px;
}

.acc-already-txt.resend-otm-timer.text-white {
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  gap: 8px;
}

.timer-box {
  font-family: "Poppins-Regular", Arial, sans-serif !important;
  font-size: 13px !important;
}

.lg-name-box {
  word-break: break-all;
}

.rc-time-picker-clear {
  width: 30px !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list,
.css-pwxzbm {
  text-transform: capitalize;
}

.css-pwxzbm {
  border: 1px solid #a6ccff;
}

.modal-img-view img {
  width: 100%;
}

.css-jcincl::before {
  border-bottom: none;
}

.nft-form-description-textfield textarea {
  scrollbar-width: thin;
}

.table-td.fixed-title {
  max-width: 300px;
  word-wrap: break-word;
}

.table-td.fixed-descrip {
  max-width: 360px;
  word-wrap: break-word;
}

.winner-reward-box {
  display: flex;
  gap: 10px;
}

.save-user-btn.primary-btn.add-remove-btn {
  min-width: auto;
  min-height: auto;
  padding: 4px 8px;
  font-size: 18px;
}

.dashboard-containt-main.buyer-token-box {
  padding: 16px;
  background-image: url(../images/svg/mobileBg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.buytoken-box {
  /* background-color: #ededed; */
  /* border-radius: 8px; */
  /* padding: 16px; */
  width: 100%;
}

.buytoken-box p {
  font-size: 16px;
  line-height: 21px;
  font-family: "Poppins-Regular", Arial, sans-serif;
  color: #fff;
  text-transform: capitalize;
  /* padding: 10px 15px; */
  font-weight: 600;
  min-height: 34px;
}

.buytoken-box .input-box {
  border: 1px solid #fff;
  width: 100%;
  padding: 4px 12px;
  /* background-color: #fff; */
  color: #fff;
  font-size: 14px;
  line-height: normal;
  border-radius: 10px;
  min-height: 20px;
  margin-bottom: 8px;
}

.buytoken-box .input-box input {
  color: #fff;
}

.buytoken-box .input-box input svg {
  filter: invert(1);
}

.buytoken-box .submit-btn-main .submit-user-btn,
.buytoken-box .submit-btn-main .submit-user-btn:hover,
.buytoken-box .comman-btn-main .primary-btn,
.buytoken-box .comman-btn-main .primary-btn:hover {
  background-color: #B837B8;
  text-align: start;
  border: 1px solid #B837B8;
}

.submit-user-btn.border-btn.claim-btn {
  font-size: 12px;
  padding: 4px 4px;
  min-width: 68px;
  border-radius: 6px;
  line-height: normal;
  min-height: auto;
  background-color: #1DE5FF;
  border: 1px solid #1DE5FF;
  font-weight: bold;
}

.buytoken-box .submit-btn-main {}

.buytoken-box .add-user-data-main {
  /* max-width: calc(100vw - 64px); */
  overflow: auto !important;
  padding: 0px;
  background-color: transparent;
  margin-bottom: 20px;
}

.page-table-main.buytoken-box .table-td {
  display: flex;
  flex: 0 0 50%;
  color: #fff;
}

.page-table-main.buytoken-box .table-td:first-of-type {
  /* font-weight: bold; */
}

.table-td.border-btm {
  border-bottom: 0;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 0px;
}

.top-right-notification {
  position: absolute;
  right: 10px;
  top: 0px;
}

w3m-button {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
}
.change-profile {
  display: flex;
  justify-content: start;
  position: relative;
}

.change-profile button{
  background-color: #05225c;
    color: #ffffff;
    min-height: 36px;
    padding: 6px 14px 5px;
    font-size: 14px;
    border: 1px solid #004dd1;
    line-height: 18px;
    border-radius: 40px;
    text-transform: capitalize;
    cursor: pointer;
    width: 160px;
}

input.change-profile-input {
  position: absolute;
  top: 0;
  background: red;
  height: 38px;
  right: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  width: 160px;
  margin: 0;  
}

.admin-tabs-main .admin-tab.mobile-tab{
  color: #ffffff !important;
  width: 50%;
}

.admin-tabs-main .admin-tab.mobile-tab.Mui-selected{
  background: #B837B8;
    text-align: start;
    border: 1px solid #B837B8;
}
textarea:focus-visible {
  outline:none;
  border-radius: 0;
}
.resize-textarea{
  resize: none !important;
}
.sub-admin-add-btn{
  display:flex;
  justify-content: flex-end;
}